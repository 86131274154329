import { Dispatch, useEffect } from "react";
import { PlaybackState } from "../../shared/videoStates";
import {useConferenceEnvironment} from "../ConferenceEnvironment";

interface AdContainerNoOpProps {
  playbackState: PlaybackState;
  setPlaybackState: Dispatch<PlaybackState>;
}

export function AdContainerNoOp({
  playbackState,
  setPlaybackState,
}: AdContainerNoOpProps) {
  const conferenceEnv = useConferenceEnvironment();


  useEffect(() => {
    if (playbackState === "requested-play") {
      setPlaybackState("all-ads-finished");
      conferenceEnv?.setConferenceState((oldState) => {
        return { ...oldState, adState: "all-ads-complete" };
      });
    }
  }, [playbackState, setPlaybackState, conferenceEnv]);
  return null;
}
