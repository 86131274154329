import { RefObject, useCallback, useLayoutEffect } from "react";
import { useEnvironment } from "../Environment";
import log from "loglevel";

interface FullscreenProps {
  containerRef: RefObject<HTMLDivElement>;
  isBig: boolean;
}

export function useFullscreenState({
  containerRef,
  isBig,
}: FullscreenProps): [boolean, (value: boolean) => void] {
  const env = useEnvironment();
  const isFullscreen = env.isFullscreen;
  const setFullscreen = env.setFullscreen;
  const onFullscreenBtnFunc = env.onFullscreenBtnFunc;
  const isApp = env.isApp;
  const toggleFullscreen = useCallback(
    (value: boolean) => {
      setFullscreen(value);
    },
    [setFullscreen]
  );
  if (isBig) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (window as any).toggleFullscreen = toggleFullscreen;
  }
  const fullScreenCallback = useCallback(
    (value: boolean) => {
      if (isApp) {
        if (onFullscreenBtnFunc && !env.isPiPActive) {
          onFullscreenBtnFunc(value);
        }
      } else {
        const pageIsFullscreen = _isInFullscreen();
        if (value && !pageIsFullscreen) {
          containerRef.current
            ? containerRef.current.requestFullscreen().catch((e) => {
                log.error(e);
                setFullscreen(false);
              })
            : null;
        } else if (!value && pageIsFullscreen) {
          document.exitFullscreen().catch((e) => {
            log.error(e);
            setFullscreen(true);
          });
        }
      }
    },
    [isApp, env.isPiPActive, onFullscreenBtnFunc, containerRef, setFullscreen]
  );
  useLayoutEffect(() => {
    document.onfullscreenchange = () => {
      const pageIsFullscreen = _isInFullscreen();
      pageIsFullscreen !== isFullscreen
        ? setFullscreen(pageIsFullscreen)
        : null;
    };
  }, [isFullscreen, setFullscreen]);
  return [isFullscreen, fullScreenCallback];
}

function _isInFullscreen() {
  return document.fullscreenElement != null;
}
