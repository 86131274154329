import { useCallback } from "react";
import { useEnvironment } from "../Environment";
import { AdMeta } from "../model/AdMeta";
import { AdTags } from "../model/AdTags";

const REPLACE_IDFA_KEY = "ids[idfa]";
const REPLACE_AAID_KEY = "ids[aaid]";
const REPLACE_IDFV_KEY = "ids[idfv]";
const REPLACE_PPID_KEY = "ids[ppid]";
const REPLACE_VERSION = "REPLACE_VERSION";
const REPLACE_BLOCK_INDEX_KEY = "block[index]";
const REPLACE_HAS_UNCERTIFIED_VENDORS = "REPLACE_HAS_UNCERTIFIED_VENDORS";
const REPLACE_TC_STRING = "REPLACE_TC_STRING";
const REPLACE_BLOCK_DURATION_KEY = "block[duration]";

export function useConstructAdTags(
  adTags: AdTags | undefined,
  isLive: boolean
): [string, (index: number, blockDuration?: number) => string, string] {
  const meta = useEnvironment().adMeta;
  const preRoll = adTags?.pre
    ? replacePlaceholders(adTags.pre, meta)
    : undefined;
  const midRoll = adTags?.mid
    ? replacePlaceholders(adTags.mid, meta)
    : undefined;
  const postRoll = adTags?.post
    ? replacePlaceholders(adTags.post, meta)
    : undefined;

  const getMidRoll = useCallback(
    (index: number, overrideDuration?: number) => {
      const result = midRoll ? replaceBlockIndex(midRoll, index + 1) : ""; // we always start with 1
      return isLive
        ? replaceBlockDuration(result, meta, overrideDuration)
        : result;
    },
    [midRoll, meta, isLive]
  );
  return [
    preRoll ? replaceBlockIndex(preRoll, 1) : "", // we always start with 1
    getMidRoll,
    postRoll ? replaceBlockIndex(postRoll, 1) : "", // we always start with 1
  ];
}

// https://mandates.somquery.sqrt-5041.de/tv/ad-pod/sportdeutschland?client%5Bcountry%5D=de&client%5Bidfa%5D=REPLACE_IDFA&client%5Bppid%5D=REPLACE_PPID&client%5Bref%5D=https%3A%2F%2Fsportdeutschland.tv&client%5Bversion%5D=REPLACE_VERSION&block%5Bname%5D=precontentextended&block%5Bindex%5D=REPLACE_BLOCK_INDEX&consent%5Buncertified_vendors%5D=REPLACE_HAS_UNCERTIFIED_VENDORS&consent%5Bgdpr_consent%5D=REPLACE_TC_STRING&inventory%5Bsite%5D=sportdeutschland&inventory%5Bbrand%5D=sportdeutschlandtv&inventory%5Bcategory%5D=paid&inventory%5Bformat%5D=
// https://mandates.somquery.sqrt-5041.de/v4/ad-pod/31fb2297-8856-4852-9010-1955aabd0f61?client[dc]=nativeapp&client[country]=de&client[ref]=http://sportdeutschland.tv&block[name]=precontent&block[index]=0&consent[uncertifiedVendors]=0&consent[gdprConsent]=CPnlY4APnr-wAE-ABBDEC5CsAP_AAH_AAAYgJAJZ8D5VTWFCOX5_QPtAOIQF18ATAmACBgSAAmABABGA4AQGgmAQJESAACACEAIAolIBAAAEHAEEAEAAQAABAAFAAAAEBAAIIABEAAEAAAIIAAoCAAAAAACIAEAREACAkACAA5IoAEAAgAAAAgAAoAAAAAAAAgEABAAIABIAAQAAAAAAAAAAEAAAAAAAAQgAAAQSAggAALAAcABUAC4AGQAOAAeABAACQAGQANAAcwA-AD-AIgAigBMgCeAKAAUoAuAC6AGIAN4AcwA_ACEAENAIgAiQBLACaAFKALEAXIAwwBogDugH2AfoBCACIgEWgI4AjoBJgC0gFzALqAXkAwIBigDcAHEAOoAi8BIgCZAFDgKRAU2ArsBeYC-gGCAMWAZYA1kB44ELgJAAAAAA.YAAAAAAAAAAA&ids[aaid]=f6028666-5bbb-471d-9fc3-ca7ae89fd24a&ids[idfa]=0
// &ids[idfv]=REPLACE_IDFV&ids[ppid]=b25d4564-3b62-4f9d-960f-9771e4afc7f4&inventory[site]=demo&inventory[brand]=demo&inventory[category]=sports&inventory[format]=sports&iabContent[id]=93cbdfc1-a1c6-4808-b7f6-8937c50dd933&iabContent[episode]=1&iabContent[title]=Mittwochstest&iabContent[series]=Ultimate+Frisbee&iabContent[season]=1&iabContent[artist]=Staging+Cup&iabContent[genre]=sports&iabContent[isrc]=&iabContent[url]=https://sportdeutschland.tv/ultimatestagingcup/mittwochstest&iabContent[cat][0]=483&iabContent[prodq]=1&iabContent[context]=1&iabContent[contentrating]=FSK+12&iabContent[sourcerelationship]=1&iabContent[keywords]=Ultimate+Frisbee,Sportdeutschland.TV,Handball&iabContent[livestream]=0&iabContent[len]=641&iabContent[language]=de

function replacePlaceholders(adTag: string, meta?: AdMeta) {
  const url = new URL(adTag);
  const params = url.searchParams;
  if (meta?.aaid) {
    params.delete(REPLACE_IDFV_KEY);
    params.delete(REPLACE_IDFA_KEY);
    params.set(REPLACE_AAID_KEY, meta?.aaid);
  } else if (meta?.idfa && meta?.idfv) {
    params.set(REPLACE_IDFA_KEY, meta?.idfa);
    params.set(REPLACE_IDFV_KEY, meta?.idfv);
    params.delete(REPLACE_AAID_KEY);
  } else {
    params.delete(REPLACE_IDFV_KEY);
    params.delete(REPLACE_IDFA_KEY);
    params.delete(REPLACE_AAID_KEY);
  }

  if (meta?.ppid) {
    params.set(REPLACE_PPID_KEY, meta?.ppid);
  } else {
    params.delete(REPLACE_PPID_KEY);
  }

  return url
    .toString()
    .replace(REPLACE_VERSION, "0.1")
    .replace(
      REPLACE_HAS_UNCERTIFIED_VENDORS,
      meta?.allConsentGiven === true ? "0" : "1"
    )
    .replace(REPLACE_TC_STRING, meta?.consent ?? "0"); // Testing: "CPUIqWvPVjJsME-ABBDECHCsAP_AAH_AAAYgHcJZ_T5VbWFCeX59VvtAOYwH18CXA-QCAgSAImABgBGQ4DwGgmASNASghigCAAIAqlJBAAAEHAEEQQAAYAAFAACgAAgEBABIIAJEgBEAAAIYAAsCAIAAAACIgEABGACAmgCIAlbqSEBggIASQgAAgAAAAIABAAAABA4sAEwVKiAAoSwwJIo0oBRACCsACoBQAQEAQBEQAAAICHAWAQggAQAAAEAEAAABAFEDAIAAAAAEAiAAADAAAgAAAAAAAAAABAABIAAIAAAACAAEAQAAAAAAQAAAAIAAAECACAAKASQgAAAACAEAAAAAAABAFFQCQAmABcAH4ARwAtIBvAEggKbAXmMgCgBMgH2AfgBHADeALzDQDgArABcAEMAPwAgoBTwC0gG8AOqAkQBOwCkQGMAMnEQCQArACGAH4AU8A6oCRAE7AKRAZOEgWgALAAqABkADgAHgAQAAyABoADyAIgAigBMACeAFYALAAbwA5gB-AEIAIaARABEgCWAE0AKUAYYA1QB7QD7AP0AjgBKQCngFzAMUAbgA3gB6AEiAJ2AUOApEBeYDBgGSAMnAacA1kAAA.YAAAAAAAAAAA&targetings%5bnuggad%5d=d15%3D5%26i2%3D1%26c4%3D0%26c2%3D1%26d1%3D1%26i8%3D1%26d8%3D1%26c5%3D0%26c3%3D0%26d12%3D4"
}

export function replaceBlockIndex(adTag: string, index: number) {
  const url = new URL(adTag);
  const params = url.searchParams;
  params.set(REPLACE_BLOCK_INDEX_KEY, index.toString());
  return url.toString();
}

function replaceBlockDuration(adTag: string, meta?: AdMeta, duration?: number) {
  const url = new URL(adTag);
  const params = url.searchParams;
  params.set(
    REPLACE_BLOCK_DURATION_KEY,
    duration?.toString() ?? `${meta?.midrollBlockDurationLive ?? 30}`
  );
  return url.toString();
}
