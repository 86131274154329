import {useEnvironment} from "../Environment";

function getImagePath(imageId: string) {
  const uuidParts = imageId.split('-');
  return `images/${uuidParts[0].split('').join('/')}/${uuidParts[1]}/${uuidParts[2]}/${uuidParts[3]}/${uuidParts[4]}`;
}

export default function useUrlHelper(){
  const { imageHost } = useEnvironment();

  return {
    posterImage: (imageId: string, size = "1920x1080") =>
        `${imageHost}/${getImagePath(imageId)}/${size}.webp`
  }
}


