export class ValidationError extends Error {
  readonly _type = "ValidationError";
}

export function isValidationError(
  v: ValidationError | unknown
): v is ValidationError {
  return (v as ValidationError)._type === "ValidationError";
}

export function normalizeMultiple<T>(
  s: T | T[] | null | undefined
): T | null | undefined {
  if (s !== null && s !== undefined) {
    if (Array.isArray(s)) {
      return s[s.length - 1];
    } else {
      return s;
    }
  } else {
    return undefined;
  }
}

export function requireString(
  name: string,
  s: string | string[] | null | undefined
): string {
  const n = normalizeMultiple(s);
  if (typeof n === "string") {
    return n;
  } else {
    throw new ValidationError(`requireString(${name})`);
  }
}

export function requireOptionalString(
  name: string,
  s: string | string[] | null | undefined
): string | undefined {
  if (s === null || s === undefined) {
    return undefined;
  }
  const n = normalizeMultiple(s);
  if (typeof n === "string") {
    return n;
  } else {
    throw new ValidationError(`requireString(${name})`);
  }
}

export function requireStringOrDefault(
  s: string | string[] | null | undefined,
  d: string
): string {
  const n = normalizeMultiple(s);
  if (typeof n === "string") {
    return n;
  } else {
    return d;
  }
}

// export function parseStringFormat(name: string, s: string | string[] | null | undefined, format: RegExp, defaultValue: string): string {
//   const n = normalizeMultiple(s);
//   if (n) {
//     if(n.match(format)) {
//       return n;
//     } else {
//       throw new ValidationError("Parameter not matching format " + name + " " + n);
//     }
//   } else {
//     return defaultValue
//   }
// }

export function parseStringEnum<T extends string>(
  name: string,
  s: string | string[] | null | undefined,
  possibleValues: ReadonlyArray<T>,
  defaultValue: T
): T {
  const n = normalizeMultiple(s);
  if (n) {
    if (possibleValues.includes(n as T)) {
      return n as T;
    } else {
      throw new ValidationError(
        "Parameter " + name + " not in " + possibleValues.toString()
      );
    }
  } else {
    return defaultValue;
  }
}

export function requireStringEnum<T extends string>(
  name: string,
  s: string | string[] | null | undefined,
  possibleValues: ReadonlyArray<T>
): T {
  const n = normalizeMultiple(s);
  if (n) {
    if (possibleValues.includes(n as T)) {
      return n as T;
    } else {
      throw new ValidationError(
        "Parameter " + name + " not in " + possibleValues.toString()
      );
    }
  } else {
    throw new ValidationError("Missing Parameter " + name);
  }
}

export function parseJSON<T>(
  name: string,
  s: string | string[] | null | undefined,
  defaultValue: T
) {
  if (s) {
    if (typeof s === "object") {
      return s;
    }
    const n = normalizeMultiple(s);
    if (n) {
      try {
        return JSON.parse(n) as T;
      } catch (e) {
        if (e instanceof Error) {
          throw new ValidationError(
            "Parameter " + name + " invalid json: " + e.message
          );
        } else {
          throw new ValidationError(
            "Parameter " + name + " invalid json: unkown error"
          );
        }
      }
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
}

export function parseUrl(
  name: string,
  url: string | undefined,
  required: boolean | undefined
): string | undefined {
  if (url) {
    try {
      new URL(url);
      return url;
    } catch (e) {
      throw new ValidationError(`Invalid url ${url} (${name})`);
    }
  } else {
    if (required) {
      throw new ValidationError("Missing Parameter " + name);
    }
    return undefined;
  }
}

export function parseBoolean(
  name: string,
  s: string | string[] | boolean | null | undefined,
  def: boolean
): boolean {
  if (s === undefined || s === null) {
    return def;
  } else if (typeof s === "boolean") {
    return s;
  } else if (Array.isArray(s)) {
    return parseBoolean(name, s[s.length - 1], def);
  } else if (s === "on" || s === "true") {
    return true;
  } else if (s === "off" || s === "false") {
    return false;
  } else {
    throw new ValidationError("Invalid " + name);
  }
}

export function requireBoolean(
  name: string,
  value: boolean | null | undefined
): boolean {
  if (typeof value === "boolean") {
    return value;
  } else {
    throw new ValidationError(name + " must be a boolean");
  }
}

export function parseNumber(
  input: number | string | string[] | null | undefined,
  min: number,
  max: number,
  def: number,
  zeroIsMax = false
): number {
  let output = def;
  if (typeof input === "number") {
    output = input;
  } else {
    const n = normalizeMultiple(input);
    if (n) {
      const parsed = Number.parseInt(n, 10);
      if (!Number.isNaN(parsed)) {
        output = parsed;
      } else {
        throw new ValidationError("Invalid number " + n);
      }
    }
  }
  if (zeroIsMax && output === 0) return max;
  if (output < min) return min;
  if (output > max) return max;
  return output;
}

export function requireNumber(
  name: string,
  inputNumber: number | null | undefined,
  min: number,
  max: number
): number {
  if (inputNumber) {
    if (!Number.isNaN(inputNumber)) {
      if (inputNumber < min) {
        throw new ValidationError(
          name + " must be greater than " + min.toString()
        );
      }
      if (inputNumber > max) {
        throw new ValidationError(
          name + " must be less than " + max.toString()
        );
      }
      return inputNumber;
    } else {
      throw new ValidationError(name + " is invalid");
    }
  } else {
    throw new ValidationError(name + " missing");
  }
}

export type PropsParser<T> = {
  [Property in keyof T]: (i: T[Property] | null | undefined) => T[Property];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Retriever<T> = (k: keyof T) => any;

// export function parseObject<T>(parser: PropsParser<T>, input: any){
//   return parseProps(parser, (key) => input[key]);
// }
//
// export function parsePartialObject<T>(parser: PropsParser<T>, input: any){
//   return parsePartialProps(parser, (key) => input[key]);
// }

export function parseProps<T>(parser: PropsParser<T>, retriever: Retriever<T>) {
  const keys = Object.keys(parser) as (keyof T)[];

  const ret: Partial<T> = {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  keys.forEach((k) => (ret[k] = parser[k](retriever(k))));

  return ret as T;
}

export function parsePartialProps<T>(
  parser: PropsParser<T>,
  retriever: Retriever<T>
): Partial<T> {
  const keys = Object.keys(parser) as (keyof T)[];
  const ret: Partial<T> = {};
  keys.forEach((k) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const v = retriever(k);
    if (v !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ret[k] = parser[k](v);
    }
  });

  return ret;
}
