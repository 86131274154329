import { QueryFunction } from "@tanstack/react-query";
import { sdtvError } from "../components/common/SdtvPlayerError";
import {
  ApiError,
  apiErrorToErrorState,
} from "../shared/errorStates";
import { Response } from "./Response";

export function fetchQuery<T>(
  url: string,
  headers = {}
): QueryFunction<Response<T>> {
  return async ({ signal }) => {
    const init = {
      signal,
      method: "GET",
      headers: headers,
    };
    const response = await fetch(url, init);
    if (!response.ok) {
      const error = (await response.json()) as ApiError;
      const errorState = apiErrorToErrorState(error);
      throw sdtvError(error.error, errorState);
    }
    return {
      status_code: response.status,
      status_text: response.statusText,
      body: (await response.json()) as T,
    };
  };
}
