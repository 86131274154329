import React, {
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ConferenceAdState } from "../shared/adStates";

interface AdQueueElement {
  conferenceIndex: number;
  adTagUrl: string;
  restoreBigIndex: number;
  restoreLastBigIndex: number;
}

const ConferenceEnvironmentContext = React.createContext<
  ConferenceEnvironmentProps | undefined
>(undefined);

interface ConferenceState {
  adState: ConferenceAdState;
  bigIndex: number;
  lastBigIndex: number;
  isOpen: boolean;
  showControls: boolean;
  adQueue: AdQueueElement[];
  midRollIndex: number;
}
interface ConferenceEnvironmentProps {
  conferenceState: ConferenceState;
  setConferenceState: Dispatch<SetStateAction<ConferenceState>>;
  isConferenceOpenRef: MutableRefObject<boolean>;
  toggleConference: () => void;
}

export function ConferenceEnvironment({ children }: PropsWithChildren) {
  const [conferenceState, setConferenceState] = useState<ConferenceState>({
    adState: "before-preroll",
    bigIndex: 0,
    lastBigIndex: -1,
    isOpen: false,
    showControls: false,
    adQueue: [],
    midRollIndex: 0,
  });
  const isConferenceOpenRef = useRef(false);
  useEffect(() => {
    isConferenceOpenRef.current = conferenceState.isOpen;
  }, [conferenceState.isOpen]);

  const toggleConference = useCallback(() => {
    setConferenceState((oldState) => {
      return {
        ...oldState,
        bigIndex: oldState.isOpen ? oldState.lastBigIndex : -1,
        lastBigIndex: oldState.isOpen ? -1 : oldState.bigIndex,
        isOpen: !oldState.isOpen,
      };
    });
  }, []);
  return (
    <ConferenceEnvironmentContext.Provider
      value={{
        conferenceState,
        isConferenceOpenRef,
        setConferenceState,
        toggleConference,
      }}
    >
      {children}
    </ConferenceEnvironmentContext.Provider>
  );
}

export function useConferenceEnvironment() {
  return useContext(ConferenceEnvironmentContext);
}
