import {
  useEffect,
  useState,
  useCallback,
} from "react";
import { useConferenceEnvironment } from "../components/ConferenceEnvironment";
import { useEnvironment } from "../Environment";
import { PlaybackState } from "./videoStates";

interface PiPProps {
  index?: number;
  playbackState: PlaybackState;
}

export function usePiPEventState({
  index,
  playbackState,
}: PiPProps): [number] {
  const conferenceEnv = useConferenceEnvironment();
  const [isRegistered, setIsRegistered] = useState(false);
  const { setIsPiPActive } = useEnvironment();

  const getVideoElement = useCallback(() => {
    return document.getElementById(
      `sdtv-player-mux${
        conferenceEnv?.conferenceState?.isOpen &&
        conferenceEnv?.conferenceState?.lastBigIndex === index
          ? "-active"
          : ""
      }`
    );
  }, [conferenceEnv?.conferenceState?.isOpen, conferenceEnv?.conferenceState?.lastBigIndex, index])

  useEffect(() => {
    const videoRefByIdTemp = getVideoElement()
    if(playbackState == "playing" && videoRefByIdTemp != null && !isRegistered) {
      videoRefByIdTemp?.addEventListener(
        "enterpictureinpicture",
        // eslint-disable-next-line
        (event: any) => {
          setIsPiPActive(true);
        }
      );
      videoRefByIdTemp?.addEventListener(
        "leavepictureinpicture",
        // eslint-disable-next-line
        (event: any) => {
          setIsPiPActive(false);
        }
      );
      setIsRegistered(true)
    }
    else if (!videoRefByIdTemp) {
      setIsRegistered(false)
      setIsPiPActive(false)
    }

  }, [playbackState, isRegistered, setIsRegistered, setIsPiPActive, getVideoElement])

  return [ 0 ];
}
