import log from "loglevel";

interface ExpandedErrorEvent extends Event {
  detail: {
    name: string;
    code: number;
    fatal: boolean;
    data: {
      response?: {
        code: number;
      };
    };
  };
}

// do not show media error for certain errors (interrupted signal). instead reset the video repeatedly to test
export const isInterruptedSignal = (e: Event) => {
  try {
    const expandedEvent = e as ExpandedErrorEvent;
    if (expandedEvent.detail.name !== "MediaError") {
      // only do this for media errors
      log.debug(
        "isInterrupted - not a media error",
        expandedEvent.detail.name
      );
      return false;
    }
    if (expandedEvent.detail.data.response?.code === 412) {
      // only do this for media errors
      log.debug("isInterrupted - true");
      return true;
    }
  } catch {
    // throw and show the actual error if casting fails
    log.debug("isInterrupted - casting media error failed for", e);
    return false;
  }
  log.debug("isInterrupted - fall through");
  return false;
};


export const isMediaError = (e: Event) => {
  try {
    const expandedEvent = e as ExpandedErrorEvent;
    if (expandedEvent.detail.name === "MediaError") {
      // only do this for media errors
      log.debug("isMediaError - not a media error", expandedEvent.detail.name);
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

// media errors can be non fatal
export const isFatalMediaError = (e: Event) => {
  try {
    const expandedEvent = e as ExpandedErrorEvent;
    if (expandedEvent.detail.name !== "MediaError") {
      // changed behaviour: do not show media error for non media errors.
      return false
    }
    if (expandedEvent.detail.fatal === false) {
      // only do this for media errors
      log.debug("isFatalMediaError - fatal is false");
      return false;
    }
  } catch {
    // throw and show the actual error if casting fails
    log.debug("isFatalMediaError - casting media error failed for", e);
    return false;
  }
  log.debug("isFatalMediaError - fall through");
  return false;
};
