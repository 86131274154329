import { useCallback, useRef } from "react";

interface DebounceProps {
  callback: () => void;
  timeout: number;
  // only for logging
  name?: string;
}

export function useDebounce({ callback, timeout, name }: DebounceProps) {
  const debounceRef = useRef<number>();
  const clearTimeoutCallback = useCallback(() => {
    console.log("clearing debounce timeout", name);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
  }, [name]);
  const setTimeoutCallback = useCallback(() => {
    clearTimeoutCallback();
    // console.log("setting debounce timeout", name);
    debounceRef.current = setTimeout(() => {
      console.log("triggering debounce callback", name);
      callback();
    }, timeout)  as unknown as number;
  }, [callback, clearTimeoutCallback, name, timeout]);
  return [setTimeoutCallback, clearTimeoutCallback];
}
