import {
  Dispatch,
  SetStateAction,
} from "react";
import BigPlayIcon from "../../assets/icons/play.svg";
import { RequestAdState } from "../../shared/adStates";

interface AdControlsProps {
  setRequestAdState: Dispatch<SetStateAction<RequestAdState>>;
}

export function AdControls({
  setRequestAdState,
  // reset timeout on every button click except pause (controls should not hide on pause)
}: AdControlsProps) {

  return (
    <div className="sdtv-big-controls">
      <div className="sdtv-big-controls-buttons">
        <BigPlayIcon
          className="sdtv-icon-big sdtv-icon-big-play"
          onClick={() => setRequestAdState("resume")}
        />
      </div>
    </div>
  );
}
