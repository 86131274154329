import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import log from "loglevel";

interface PipProps {
  videoRef: RefObject<HTMLVideoElement>;
}

export function usePipState({
  videoRef,
}: PipProps): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [isPip, setIsPip] = useState(false);

  useEffect(() => {
    const pageIsPip = _isPip();
    if (isPip && !pageIsPip) {
      videoRef.current
        ? videoRef.current.requestPictureInPicture().catch((e) => {
            log.error(e);
            setIsPip(false);
          })
        : null;
    } else if (!isPip && pageIsPip) {
      document.exitPictureInPicture().catch((e) => {
        log.error(e);
        setIsPip(true);
      });
    }
  }, [isPip, videoRef]);
  return [isPip, setIsPip];
}

function _isPip() {
  return document.pictureInPictureElement != null;
}
