import { useQuery } from "@tanstack/react-query";
import { Response } from "./Response";
import { useQueryFunction } from "./useQueryFunction";
import { useEnvironment } from "../Environment";

interface Midroll {
  id: string;
  asset_id: string;
  start_time: string;
  blocks: number;
  duration_in_seconds?: number;
}

interface GetMidrollProps {
  assetId: string;
  enabled: boolean;
  intervall?: number;
}

export function useGetMidroll({
  assetId,
  enabled,
  intervall,
}: GetMidrollProps) {
  const { host } = useEnvironment();
  const queryFn = useQueryFunction<Midroll[]>(
    `${host}/api/stateless/assets-midroll/${assetId}`
  );
  return useQuery<Response<Midroll[]>>(["GetMidroll", assetId], queryFn, {
    // refetch every 10 seconds
    refetchInterval: (intervall ?? 10) * 1000,
    enabled: enabled,
  });
}
