import { useEnvironment } from "../Environment";
import { fetchQuery } from "./fetchQuery";

export function useQueryFunction<T>(
  url: string
) {
  const bearerToken = useEnvironment().bearerToken;
  return fetchQuery<T>(url, {
    Authorization: bearerToken,
  });
}

export function useQueryFunctions<T>(
  urls: string[]
) {
  return urls.map((url) => fetchQuery<T>(url));
}
