import FullscreenIcon from "../../assets/icons/maximize.svg";
import CloseFullscreenIcon from "../../assets/icons/minimize.svg";
import AirplayIcon from "../../assets/icons/airplay.svg";
import PipIcon from "../../assets/icons/pip.svg";
import { PlaybackState, showAllControls } from "../../shared/videoStates";
import { Progress } from "./Progress";
import { Time } from "./Time";
import { VolumeState } from "../../shared/useVolumeState";
import { useEnvironment } from "../../Environment";
import {CastContextType} from "../../shared/CastProvider";
import {CastPlayer} from "../../shared/useCastPlayer";


declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'google-cast-launcher': any
    }
  }
}

interface ControlsProps {
  playbackState: PlaybackState;
  progress: number;
  setSeekTo: (value: number) => void;
  setProgress: (value: number) => void;
  setProgressIsChanging: (value: boolean) => void;
  duration: number;
  volume: VolumeState;
  setVolume: (value: VolumeState) => void;
  isFullscreen: boolean;
  setIsFullscreen: (value: boolean) => void;
  isPip: boolean;
  setIsPip: (value: boolean) => void;
  isLive: boolean;
  setHideControlsTimeout?: () => void;
  showAirplay: boolean;
  showActiveAirplay: boolean;
  clickAirplayCallback: () => void;
  cast: CastContextType;
  castPlayer: CastPlayer;
}

export function Controls({
  playbackState,
  progress,
  setSeekTo,
  setProgress,
  setProgressIsChanging,
  duration,
  isFullscreen,
  setIsFullscreen,
  isPip,
  setIsPip,
  isLive,
  // reset timeout on every button click
  setHideControlsTimeout = () => undefined,
  showAirplay,
  showActiveAirplay,
  clickAirplayCallback,
  cast,
}: ControlsProps) {
  const env = useEnvironment();
  const showAll = showAllControls(playbackState);


  return (
    <div className="sdtv-controls" onClick={(e) => e.stopPropagation()}>
      <div className="sdtv-bottom-controls">
        <div className="sdtv-bottom-controls-middle">
          {(showAll && !cast.connected) ? (
            <div className="sdtv-controls-time">
              <Time duration={duration} progress={progress} isLive={isLive} />
            </div>
          ) : null}
          {(showAll && !cast.connected) ? (
            <Progress
              duration={duration}
              progress={progress}
              setSeekTo={setSeekTo}
              setProgress={setProgress}
              setProgressIsChanging={setProgressIsChanging}
              isLive={isLive}
              onChange={() => {
                if (env.isApp) {
                  setHideControlsTimeout();
                }
              }}
            />
          ) : null}
        </div>
        <div className="sdtv-bottom-controls-right">
          {
            cast.initialized &&
              <>
                <button style={{ width: '20px', height: '20px' }}>
                  <google-cast-launcher></google-cast-launcher>
                </button>
              </>
          }
          {
            showAirplay
              ? <AirplayIcon
                className={`sdtv-icon sdtv-icon-airplay ${showActiveAirplay ? 'sdtv-icon-airplay-active' : ''}`}
                onClick={() => {
                  clickAirplayCallback();
                }}
              />
              : null
          }
          {!cast.connected ?
              isFullscreen ? (
                <CloseFullscreenIcon
                  className="sdtv-icon sdtv-icon-close-fullscreen"
                  onClick={() => {
                    if (env.isApp) {
                      setHideControlsTimeout();
                    }
                    setIsFullscreen(false);
                  }}
                />
              ) : (
              <FullscreenIcon
                className="sdtv-icon sdtv-icon-fullscreen"
                onClick={() => {
                  if (env.isApp) {
                    setHideControlsTimeout();
                  }
                  setIsFullscreen(true);
                }}
              />) : null}
          {!cast.connected && document.pictureInPictureEnabled && showAll && (
            <PipIcon
              className="sdtv-icon sdtv-icon-pip"
              onClick={() => {
                if (env.isApp) {
                  setHideControlsTimeout();
                }
                setIsPip(!isPip);
              }}
            />
          )}
        </div>

        {/* <Volume volume={volume} setVolume={setVolume} /> */}
      </div>
    </div>
  );
}
