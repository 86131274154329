import { useQueries, useQuery } from "@tanstack/react-query";
import { StreamType } from "../model/Asset";
import { ErrorState } from "../shared/errorStates";
import { Response } from "./Response";
import { useQueryFunction, useQueryFunctions } from "./useQueryFunction";
import {useEnvironment} from "../Environment";

export interface GetTokenData {
  token: string;
}

interface GetTokenProps {
  resourceId: string;
  resourceSrc: string;
  resourceType?: StreamType;
}

export function useGetToken({
  resourceId,
  resourceType = "mux_vod",
  resourceSrc,
}: GetTokenProps) {
  const {host} = useEnvironment();
  const params = getParams(resourceId, resourceSrc, resourceType);
  const queryFn = useQueryFunction<GetTokenData>(
    `${host}/api/app/personal/asset-token/${params}`,
    // `https://api.sportdeutschland.tv/api/app/personal/asset-token/${params}`,
  );
  return useQuery<Response<GetTokenData>>(["Token", resourceId, resourceSrc], queryFn, {
    // prevent refetching token all the time as it does not expire that fast
    staleTime: 1000 * 60 * 30,
  });
}

function getParams(
  resourceId: string,
  resourceSrc: string,
  resourceType: StreamType
): string {
  return `${resourceId}?type=${resourceType}&playback_id=${encodeURIComponent(
      resourceSrc
  )}`;

  // playback_id was missing for playlists. remove this?
  // if (resourceType === "mux_live") {
  //   return `${resourceId}?type=${resourceType}&playback_id=${encodeURIComponent(
  //     resourceSrc
  //   )}`;
  // } else {
  //   return `${resourceId}?type=${resourceType}`;
  // }
}

export interface VideoPlayerVideo {
  id: string;
  src: string;
  imageId?: string;
}

interface GetTokensProps {
  videos?: VideoPlayerVideo[];
  setErrorState: (state: ErrorState) => void;
  resourceSrc: string;
  resourceType?: StreamType;
}

export function useGetTokens({
  videos,
  resourceSrc,
  resourceType = "mux_vod",
}: GetTokensProps) {
  return useQueries<Response<GetTokenData>[]>({
    queries: useQueryFunctions<GetTokenData>(
      videos
        ? videos.map(
            (video) =>
              `https://api.sportdeutschland.tv/api/frontend/asset-token/${getParams(
                video.id,
                resourceSrc,
                resourceType
              )}`
          )
        : [""],
    ).map((queryFn, i) => {
      return {
        queryKey: ["Token", videos ? videos[i] : ""],
        queryFn: queryFn,
        // prevent refetching token all the time as it does not expire that fast
        staleTime: 1000 * 60 * 30,
        enabled: videos !== undefined,
      };
    }),
  });
}
