export type PlaybackState =
  | "loading"
  | "idle"
  | "init-ads"
  | "requested-ads"
  | "next-ad"
  | "ads-finished"
  | "all-ads-finished"
  | "requested-play"
  | "playing"
  | "paused"
  | "error"
  | "ended"
  | "prelive"
  | "retrying";

export function showConferenceControls(playbackState: PlaybackState) {
  switch (playbackState) {
    case "idle":
    case "init-ads":
    case "requested-ads":
    case "ads-finished":
    case "requested-play":
      return false;
    default:
      return true;
  }
}

export function showAllControls(playbackState: PlaybackState) {
  // only show play and fullscreen button before ads have run
  switch (playbackState) {
    case "loading":
    case "idle":
    case "retrying":
    case "init-ads":
    case "requested-ads":
    case "ads-finished":
    case "requested-play":
      return false;
    default:
      return true;
  }
}
