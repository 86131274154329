export function stringifyEvent(e: Event) {
  const obj: Record<string, unknown> = {};
  for (const k in e) {
    const l = k as keyof Event;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    obj[k] = e[l];
  }
  try {
    return JSON.stringify(
      obj,
      (k, v) => {
        if (k === "loader") return "Loader";
        if (v instanceof Node) return "Node";
        if (v instanceof Window) return "Window";
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return v;
      },
      " "
    );
  } catch (error) {
    console.error(error);
    return `could not stringify event`;
  }
}
