import React, { useCallback, useRef } from "react";
import { useDebounce } from "../../shared/useDebounce";

interface ProgressProps {
  progress: number;
  setSeekTo: (value: number) => void;
  setProgress: (value: number) => void;
  setProgressIsChanging: (value: boolean) => void;
  duration: number;
  isLive: boolean;
  onChange: () => void;
}

export function Progress({
  progress,
  setSeekTo,
  setProgress,
  setProgressIsChanging,
  duration,
  isLive,
  onChange,
}: ProgressProps) {
  const lastInputRef = useRef<number>(0);
  const [setSeekToDebounced] = useDebounce({
    callback: () => {
      setSeekTo(lastInputRef.current);
      setProgressIsChanging(false);
    },
    timeout: 500,
    name: "progress",
  });
  // adapted from https://stackoverflow.com/a/37623959
  // this is set to true on browsers (everyone except IE) that are using both, the input event and the change event, to support all and prevent double firing
  const useInputEvent = useRef(false);
  const actualCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange();
      setProgress(parseFloat(e.target.value));
      setProgressIsChanging(true);
      setSeekToDebounced();
    },
    [onChange, setProgress, setProgressIsChanging, setSeekToDebounced]
  );
  const onInputCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log("progress", "oninput");
      useInputEvent.current = true;
      const currentValue = parseFloat(e.target.value);
      if (lastInputRef.current !== currentValue) {
        actualCallback(e);
      }
      lastInputRef.current = currentValue;
    },
    [actualCallback]
  );
  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log("progress", "onchange");
      if (!useInputEvent) {
        actualCallback(e);
      }
    },
    [actualCallback]
  );

  return (
    <div className="sdtv-controls-progress">
      <progress value={progress} max={duration} />
      {!isLive ? (
        <input
          className="sdtv-controls-seek"
          type="range"
          value={progress}
          min={0}
          step={0.01}
          max={duration}
          onChange={onChangeCallback}
          onInput={onInputCallback}
        />
      ) : null}
    </div>
  );
}
