import Back15Icon from "../../assets/icons/player_15_back.svg";
import Forward15Icon from "../../assets/icons/player_15_forward.svg";
import BigPlayIcon from "../../assets/icons/play.svg";
import BigPauseIcon from "../../assets/icons/pause.svg";
import { PlaybackState, showAllControls } from "../../shared/videoStates";
import { useEnvironment } from "../../Environment";
import { LoadingIndicator } from "../common/LoadingIndicator";
import {useAssetPlayerState, usePlayerStateDispatch} from "../../PlayerStateContext";
import {CastContextType} from "../../shared/CastProvider";
import {CastPlayer} from "../../shared/useCastPlayer";

interface BigControlsProps {
  assetId: string;
  imageUrl?: string;
  playbackState: PlaybackState;
  setPlaybackState: (s: PlaybackState) => void;
  progress: number;
  setSeekTo: (value: number) => void;
  isLive: boolean;
  setHideControlsTimeout?: () => void;
  clearHideControlsTimeout?: () => void;
  cast: CastContextType;
  castPlayer: CastPlayer;
}

export function BigControls({
  assetId,
  imageUrl,
  playbackState,
  setPlaybackState,
  progress,
  setSeekTo,
  isLive,
  // reset timeout on every button click except pause (controls should not hide on pause)
  setHideControlsTimeout = () => undefined,
  clearHideControlsTimeout = () => undefined,
  cast,
  castPlayer,
}: BigControlsProps) {
  const env = useEnvironment();
  const showAll = showAllControls(playbackState);
  const dispatch = usePlayerStateDispatch();
  const {ended: assetEnded} = useAssetPlayerState(assetId);

  const showPause = cast.connected ? (castPlayer.isMediaLoaded && !castPlayer.isPaused) : playbackState === "playing";
  const showPlay = cast.connected ? (!castPlayer.isMediaLoaded || castPlayer.isPaused) : playbackState === "paused" || playbackState === "idle" || playbackState === "ended";
  const showJumpTime = cast.connected ? (!isLive && castPlayer.isMediaLoaded) : !isLive && showAll;

  return (
    <div className="sdtv-big-controls">
      <div className="sdtv-big-controls-buttons">
        {showJumpTime ? (
          <Back15Icon
            className="sdtv-icon-big sdtv-icon-big-back"
            onClick={(e) => {
              if (env.isApp) {
                setHideControlsTimeout();
              }
              if(cast.connected){
                castPlayer.seek(castPlayer.currentTime - 15);
              } else {
                setSeekTo(progress - 15);
              }

              e.stopPropagation();
            }}
          />
        ) : null}
        {showPause ? (
          <BigPauseIcon
            className="sdtv-icon-big sdtv-icon-big-pause"
            onClick={(e) => {
              if (env.isApp) {
                clearHideControlsTimeout();
              }
              if(cast.connected){
                castPlayer.togglePlay();
              } else {
                setPlaybackState("paused");
              }
              e.stopPropagation();
            }}
          />
        ) : showPlay ? (
          <BigPlayIcon
            className="sdtv-icon-big sdtv-icon-big-play"
            onClick={(e) => {
              if (env.isFirstTrack) {
                env.trackingFunc("Video Start Click");
                env.trackingFunc("Video View");
              }
              if (env.isApp) {
                setHideControlsTimeout();
              }
              if (assetEnded) {
                console.log("restart asset");
                dispatch({type: "asset-restart", assetId});
              }
              if(cast.connected){
                castPlayer.togglePlay();
              } else {
                setPlaybackState("requested-play");
              }
              e.stopPropagation();
            }}
          />
        ) : playbackState === "retrying" ? (
          <LoadingIndicator
            imageUrl={imageUrl}
            loadingMessage={
              "Wir empfangen derzeit kein Signal.\nDer Stream startet automatisch sobald der Inhalt verfügbar ist."
            }
          />
        ) : (
          <LoadingIndicator />
        )}
        {showJumpTime ? (
          <Forward15Icon
            className="sdtv-icon-big sdtv-icon-big-forward"
            onClick={(e) => {
              if (env.isApp) {
                setHideControlsTimeout();
              }
              if(cast.connected){
                castPlayer.seek(castPlayer.currentTime + 15);
              } else {
                setSeekTo(progress + 15);
              }
              e.stopPropagation();
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
