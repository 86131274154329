import {
  EventHandler,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";

interface MaybeDoubleClickProps<T extends SyntheticEvent> {
  onClick: (e: T) => void;
  onDoubleClick: (e: T) => void;
  delay?: number;
}

export function useMaybeDoubleClick<T extends SyntheticEvent>({
  onClick,
  onDoubleClick,
  delay = 250,
}: MaybeDoubleClickProps<T>): EventHandler<T> {
  const [clicks, setClicks] = useState(0);
  const eventRef = useRef<T | null>(null);
  useEffect(() => {
    let singleClickTimer: number;
    if (clicks === 1) {
      singleClickTimer = setTimeout(function () {
        eventRef.current ? onClick(eventRef.current) : null;
        setClicks(0);
        eventRef.current = null;
      }, delay) as unknown as number;
    } else if (clicks === 2) {
      eventRef.current ? onDoubleClick(eventRef.current) : null;
      setClicks(0);
      eventRef.current = null;
    }
    return () => clearTimeout(singleClickTimer);
  }, [clicks, delay, onClick, onDoubleClick]);
  return (e) => {
    eventRef.current = e;
    setClicks(clicks + 1);
  };
}
