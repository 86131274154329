import { ErrorView } from "./GlobalErrorBoundary";

interface LoadingIndicatorProps {
  imageUrl?: string;
  loadingMessage?: string;
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  return (
    <div
      className={`sdtv-loading-wrapper ${
        props.loadingMessage ? "sdtv-loading-wrapper-error" : ""
      }`}
    >
      {props.imageUrl && (
        <img src={props.imageUrl} className="sdtv-loading-image" />
      )}
      {!props.loadingMessage && <div className="sdtv-loading" />}
      {props.loadingMessage && (
        <ErrorView message={props.loadingMessage} loadingView />
      )}
    </div>
  );
}
