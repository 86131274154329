import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useGetToken } from "../api/useGetToken";
import { PlaybackState, showConferenceControls } from "../shared/videoStates";
import MuxVideo from "@mux/mux-video-react";
import useUrlHelper from "../shared/urlHelper";
import { useVolumeState } from "../shared/useVolumeState";
import { useFullscreenState } from "../shared/useFullscreenState";
import { usePipState } from "../shared/usePipState";
import { useMaybeDoubleClick } from "../shared/clickHelper";
import { BigControls } from "./controls/BigControls";
import { Controls } from "./controls/Controls";
import { AdContainer } from "./ads/AdContainer";
import { RequestAdState } from "../shared/adStates";
import { useEnvironment } from "../Environment";
import { useConferenceEnvironment } from "./ConferenceEnvironment";
import { LoadingIndicator } from "./common/LoadingIndicator";
import { useDebounce } from "../shared/useDebounce";
import { Asset } from "../model/Asset";
import { stringifyEvent } from "../shared/jsonHelper";
import { isInFuture } from "../shared/dateHelper";
import { useAirplayState } from "../shared/useAirplayState";
import { AdContainerNoOp } from "./ads/AdContainerNoOp";
import { sdtvError } from "./common/SdtvPlayerError";
import {
  isInterruptedSignal,
  isFatalMediaError,
  isMediaError,
} from "../shared/errorEvent";
import { useQueryClient } from "@tanstack/react-query";
import {
  useAssetPlayerState,
  usePlayerStateDispatch,
} from "../PlayerStateContext";
import log from "loglevel";
import { useCast } from "../shared/CastProvider";
// import { DebugAdButtons } from "./ads/DebugAdButtons";
import { AdControls } from "./controls/AdControls";
import { CastPlayer, AssetPlayerContext } from "../shared/useCastPlayer";
import { usePiPEventState } from "../shared/usePiPEventState";

const isSafari =
  /AppleWebKit/.test(navigator.userAgent) &&
  /Apple Computer/.test(navigator.vendor);

interface AssetPlayerProps {
  className?: string;
  asset: Asset;
  imageSize?: string;
  conferenceIndex?: number;
  castPlayer: CastPlayer;
}

export function AssetPlayer({
  className,
  imageSize,
  asset,
  conferenceIndex = 0,
  castPlayer,
}: AssetPlayerProps) {
  const env = useEnvironment();
  const queryClient = useQueryClient();
  // undefined if we are not in a liveconference!
  const conferenceEnv = useConferenceEnvironment();

  // can only be false inside a live conference
  const isActiveConferencePlayer =
    conferenceEnv === undefined ||
    conferenceEnv?.conferenceState.bigIndex === conferenceIndex;

  const {
    id,
    image_id: imageId,
    name,
    flags: assetFlags = [],
    content_start_date: startDate,
  } = asset;

  const playerState = useAssetPlayerState(id);
  const { latestCurrentTime, currentVideoIndex } = playerState;
  const dispatch = usePlayerStateDispatch();

  // console.log(playerState);

  const adTags = asset.video_ad_tags_somquery || asset.video_ad_tags;
  const isLive = asset.type === "LIVESTREAM"; //TODO: Matthias check if that is correct

  let src = "",
    resourceType,
    isPlaylist = false,
    videoCount = 0;

  if (asset.videos && asset.videos.length > 0) {
    src = asset.videos[currentVideoIndex].src;
    resourceType = asset.videos[currentVideoIndex].type;
    isPlaylist = asset.videos.length > 1;
    videoCount = asset.videos.length;
  } else if (asset.livestream) {
    src = asset.livestream.src;
    resourceType = asset.livestream.type;
  }

  const { data: tokenData, ...tokenQuery } = useGetToken({
    resourceId: id,
    resourceSrc: src,
    resourceType,
  });

  const { posterImage } = useUrlHelper();

  const imageUrl = imageId ? posterImage(imageId, imageSize) : undefined;
  const playbackId = tokenData
    ? `${src}?token=${tokenData.body.token}`
    : undefined;
  const playbackUrl = playbackId
    ? `https://stream.mux.com/${playbackId}`
    : null;
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [playbackState, setPlaybackState] = useState<PlaybackState>("loading");
  const playbackStateRef = useRef(playbackState);
  useEffect(() => {
    playbackStateRef.current = playbackState;
  }, [playbackState, playbackStateRef]);
  // const videoRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressIsChanging, setProgressIsChanging] = useState(false);
  const [seekTo, setSeekTo] = useState(0);
  const playerLoaded = useRef(false);
  const [volume, setVolume] = useVolumeState({
    isBig: true,
    videoRef: videoRef,
  });
  const [isFullscreen, fullscreenCallback] = useFullscreenState({
    containerRef: videoContainerRef,
    isBig: isActiveConferencePlayer,
  });
  const [isPip, setIsPip] = usePipState({
    videoRef,
  });

  const [showAirplay, clickAirplayCallback, showActiveAirplay] =
    useAirplayState({
      videoRef,
      index: conferenceIndex,
      playbackState,
      setPlaybackState,
    });

  // eslint-disable-next-line
  const [isPiPActive] = usePiPEventState({
    index: conferenceIndex,
    playbackState,
  });

  const cast = useCast();

  const [showControls, setShowControls] = useState(true);
  const [showAdControls, setShowAdControls] = useState(false);
  const setConferenceState = conferenceEnv?.setConferenceState;
  const controlsCallback = env.onControlsVisibilityFunc;
  const confAssetChangedCallback = env.onConfAssetChangedFunc;
  const [requestAdState, setRequestAdState] =
    useState<RequestAdState>("uninitialized");

  // load player initially to prevent safari "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission"
  if (isSafari) {
    if (playbackState === "requested-play" && !playerLoaded.current) {
      videoRef.current?.load();
      playerLoaded.current = true;
    }
  }

  useEffect(() => {
    //console.log("requestAdState " + requestAdState);

    if (requestAdState == "running" && env.stop) {
      setShowAdControls(true);
      setRequestAdState("pause");
    } else if (requestAdState == "pause" && !env.stop) {
      setShowAdControls(false);
      setRequestAdState("resume");
    } else if (env.stop && videoRef.current) {
      videoRef.current.pause();
      // videoRef.current.removeAttribute("src");
      // videoRef.current.load();
    }
  }, [env.stop, requestAdState, setRequestAdState, videoRef]);

  useEffect(() => {
    if (controlsCallback) {
      controlsCallback(showControls);
    }
    if (
      setConferenceState &&
      showConferenceControls(playbackStateRef.current)
    ) {
      setConferenceState((oldState) => {
        return { ...oldState, showControls: showControls };
      });
    }
  }, [setConferenceState, controlsCallback, showControls]);

  const doTrack = env.trackingFunc;
  const adsFinished = env.adsFinishedFunc;
  const retryCounterRef = useRef(0);

  const doPlay = useCallback(() => {
    if (playbackState !== "playing") {
      try {
        if (!videoRef.current) {
          throw Error("no videoref set");
        }
        if (isLive) {
          videoRef.current.currentTime = Number.MAX_SAFE_INTEGER;
        } else if (latestCurrentTime !== undefined) {
          videoRef.current.currentTime = latestCurrentTime;
        }
        videoRef.current
          ?.play()
          .then(() => {
            if (env.isFirstTrack) {
              doTrack("Video Start");
              env.setFirstTrack(false);
            }
            setPlaybackState("playing");
            retryCounterRef.current = 0;
          })
          .catch((e) => {
            log.error(e);
            if (e instanceof Event) {
              setPlaybackState("error");
              setPlaybackState(() => {
                throw sdtvError(
                  `Error during 'play': ${stringifyEvent(e)}`,
                  "player-error"
                );
              });
            } else {
              if (retryCounterRef.current < 3) {
                setPlaybackState("paused");
                retryCounterRef.current += 1;
              } else {
                setPlaybackState("error");
                setPlaybackState(() => {
                  throw sdtvError(
                    `Error during 'play': ${String(e)}`,
                    "player-error"
                  );
                });
              }
            }
          });
      } catch (e) {
        if ((e as Error).message) {
          throw sdtvError(
            `error playing video asset with playbackid: ${
              playbackId ?? ""
            }, error: ${(e as Error).message}`,
            "player-error"
          );
        } else {
          throw sdtvError(
            `error playing video asset with playbackid: ${
              playbackId ?? ""
            }, error: ${String(e)}`,
            "player-error"
          );
        }
      }
    }
  }, [
    latestCurrentTime,
    playbackState,
    isLive,
    env,
    doTrack,
    playbackId,
    videoRef,
    retryCounterRef,
  ]);
  useEffect(() => {
    // console.log(
    //   "conference play pause effect, state:",
    //   conferenceEnv?.conferenceState,
    //   "isBig",
    //   isBig,
    //   "playbackState",
    //     playbackState
    // );
    // only in conference mode aftrer first preroll played
    if (
      conferenceEnv &&
      conferenceEnv.conferenceState.adState === "all-ads-complete" &&
      !cast.connected
    ) {
      // play all videos when conference is open
      if (
        (isActiveConferencePlayer && playbackState != "paused") ||
        (conferenceEnv.conferenceState.isOpen &&
          conferenceEnv?.conferenceState?.lastBigIndex == conferenceIndex)
      ) {
        doPlay();
      }
      // only play big video when conference is closed
      else if (!isActiveConferencePlayer) {
        setPlaybackState("paused");
      }
    }
  }, [conferenceEnv?.conferenceState.adState]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideControlsCallback = useCallback(() => {
    if (
      playbackStateRef.current === "playing" &&
      conferenceEnv?.isConferenceOpenRef.current !== true
    ) {
      console.log(
        "triggering hide controls callback",
        playbackStateRef.current,
        conferenceEnv?.isConferenceOpenRef.current
      );
      setShowControls(false);
    }
  }, [conferenceEnv?.isConferenceOpenRef]);
  const [setHideControlsTimeout, clearHideControlsTimeout] = useDebounce({
    callback: hideControlsCallback,
    timeout: 2500,
    name: "hide-controls",
  });

  useEffect(() => {
    switch (playbackState) {
      case "all-ads-finished":
        if (adsFinished) {
          adsFinished();
        }
        setShowControls(true);
        setHideControlsTimeout();
        doPlay();
        break;
      case "requested-ads":
        setShowControls(false);
        videoRef.current?.pause();
        break;
      case "paused":
        videoRef.current?.pause();
        break;
    }
  }, [doPlay, playbackState, setHideControlsTimeout, videoRef, adsFinished]);

  // useEffect(() => {
  //   if(playbackState === "playing" && videoRef.current && videoRef.current.paused){
  //     if(!cast.connected){
  //       setPlaybackState("requested-play");
  //     }
  //   }
  // }, [currentVideoIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (videoRef.current && !progressIsChanging) {
      videoRef.current.currentTime = seekTo;
    }
  }, [progressIsChanging, seekTo, videoRef]);

  const doToggleControls = useCallback(() => {
    setShowControls((value) => {
      if (value && playbackState === "playing") {
        return false;
      } else if (!value && playbackState !== "requested-ads") {
        setHideControlsTimeout();
        return true;
      } else return value;
    });
  }, [playbackState, setHideControlsTimeout]);

  const doShowControls = useCallback(() => {
    if (playbackState !== "requested-ads") {
      setShowControls(true);
    }
  }, [playbackState]);

  const doHideControls = useCallback(() => {
    playbackState === "playing" ? setShowControls(false) : undefined;
  }, [playbackState]);

  const singleClickCallback: (e: SyntheticEvent) => void = useCallback(
    (e) => {
      if (playbackState === "prelive") {
        return;
      }
      if (setConferenceState && isActiveConferencePlayer === false) {
        setConferenceState((oldState) => {
          if (
            oldState.isOpen &&
            oldState.lastBigIndex !== conferenceIndex &&
            confAssetChangedCallback
          ) {
            confAssetChangedCallback(conferenceIndex);
          }
          return {
            ...oldState,
            lastBigIndex: oldState.bigIndex,
            bigIndex: conferenceIndex,
            isOpen: false,
          };
        });
      } else if (env.isApp) {
        if (
          playbackStateRef.current === "requested-ads" &&
          requestAdState === "running"
        ) {
          env.stopVideo();
        } else if (
          playbackStateRef.current === "requested-ads" &&
          requestAdState === "pause"
        ) {
          env.resumeVideo();
        }
        e.stopPropagation();
        doToggleControls();
      } else if (playbackState !== "requested-ads") {
        setPlaybackState(
          playbackStateRef.current !== "playing" ? "requested-play" : "paused"
        );
      } else {
        e.preventDefault();
      }
    },
    [
      env,
      setConferenceState,
      doToggleControls,
      confAssetChangedCallback,
      conferenceIndex,
      isActiveConferencePlayer,
      playbackState,
      requestAdState,
    ]
  );
  const doubleClickCallback: (e: SyntheticEvent) => void = useCallback(
    (e) => {
      if (playbackState === "prelive") {
        return;
      }
      if (setConferenceState && isActiveConferencePlayer === false) {
        setConferenceState((oldState) => {
          if (
            oldState.isOpen &&
            oldState.lastBigIndex !== conferenceIndex &&
            confAssetChangedCallback
          ) {
            confAssetChangedCallback(conferenceIndex);
          }
          return {
            ...oldState,
            lastBigIndex: oldState.bigIndex,
            bigIndex: conferenceIndex,
            isOpen: false,
          };
        });
      } else if (!env.isApp && playbackState !== "requested-ads") {
        fullscreenCallback(!isFullscreen);
      } else {
        e.preventDefault();
      }
    },
    [
      setConferenceState,
      isActiveConferencePlayer,
      confAssetChangedCallback,
      playbackState,
      conferenceIndex,
      fullscreenCallback,
      isFullscreen,
      env,
    ]
  );
  const containerClicked = useMaybeDoubleClick({
    onClick: singleClickCallback,
    onDoubleClick: doubleClickCallback,
  });

  const onVideoEnded = useCallback(() => {
    doTrack("Position 100%");
    if (isPlaylist && currentVideoIndex < videoCount - 1) {
      log.debug("MuxVideoWrapper", "video ended, playing next video");
      videoRef.current ? (videoRef.current.currentTime = 0) : undefined;
      dispatch({ type: "asset-next-playlist-video", assetId: id });
      setPlaybackState("requested-play");
    } else {
      dispatch({ type: "asset-ended", assetId: id });
      setPlaybackState("ended");
      setRequestAdState("request-postroll");
    }
    setShowControls(true);
  }, [
    isPlaylist,
    currentVideoIndex,
    videoCount,
    dispatch,
    doTrack,
    videoRef,
    id,
  ]);

  const onVideoError = useCallback(
    (e: SyntheticEvent) => {
      console.log(
        "onVideoError",
        playbackState,
        e,
        e.nativeEvent,
        stringifyEvent(e.nativeEvent)
      );
      if(playbackState == "init-ads" ||
        playbackState == "requested-ads" ||
        playbackState == "next-ad" ||
        playbackState == "ads-finished" ||
        playbackState == "all-ads-finished") {
        console.log("ignore error, we are playing ads")
        return;
      }

      if (env.stop) {
        // do nothing, this error is the only way to ensure the player is stopped
      } else if (startDate && isInFuture(startDate)) {
        setPlaybackState("prelive");
        setShowControls(false);
      } else {
        if (isInterruptedSignal(e.nativeEvent)) {
          log.warn("video intermittent, retrying...");
          setPlaybackState("retrying");
          new Promise((resolve) => setTimeout(resolve, 30000))
            .then(() => {
              queryClient.setQueryData(["Token", id], null);
            })
            .then(() => new Promise((resolve) => setTimeout(resolve, 50)))
            .then(() => {
              queryClient.setQueryData(["Token", id], tokenData);
            })
            .catch((e) => {
              log.error("media error refetch failed: ", e);
              setPlaybackState("error");
              setPlaybackState(() => {
                throw sdtvError(`media error refetch event`, "media-error");
              });
            });
        } else if (isMediaError(e.nativeEvent)) {
          if (isFatalMediaError(e.nativeEvent)) {
            setPlaybackState("error");
            setPlaybackState(() => {
              throw sdtvError(
                `media error event: ${stringifyEvent(e.nativeEvent)}`,
                "media-error"
              );
            });
          } else {
            log.warn("Not fatal media error: try ignoring");
          }
        } else {
          if (e && e.nativeEvent && e.nativeEvent.cancelable) {
            //try to ingore cancelable errors
            log.warn("Canceable error, ignoring");
          } else {
            setPlaybackState("error");
            setPlaybackState(() => {
              throw sdtvError(
                `unknown media error event: ${stringifyEvent(e.nativeEvent)}`,
                "media-error"
              );
            });
          }
        }
      }
    },
    [env.stop, id, queryClient, startDate, tokenData, playbackState]
  );

  const onDurationChanged = useCallback(() => {
    setDuration(videoRef.current?.duration ?? duration);
  }, [duration, videoRef]);

  const onProgressChanged = useCallback(() => {
    if (videoRef.current?.currentTime && !progressIsChanging) {
      setProgress(videoRef.current?.currentTime);
      dispatch({
        type: "asset-update-time",
        assetId: id,
        time: videoRef.current?.currentTime,
      });
    }
  }, [progressIsChanging, videoRef, dispatch, id]);

  const [lastPercentage, setLastPercentage] = useState(0);
  useEffect(() => {
    if (!progressIsChanging) {
      const percentage = progress / duration;
      const percentageFixed = Math.round(percentage * 1e2) / 1e2;
      if (lastPercentage < 0.9 && percentageFixed === 0.9) {
        doTrack("Position 90%");
      } else if (lastPercentage < 0.8 && percentageFixed === 0.8) {
        doTrack("Position 80%");
      } else if (lastPercentage < 0.7 && percentageFixed === 0.7) {
        doTrack("Position 70%");
      } else if (lastPercentage < 0.6 && percentageFixed === 0.6) {
        doTrack("Position 60%");
      } else if (lastPercentage < 0.5 && percentageFixed === 0.5) {
        doTrack("Position 50%");
      } else if (lastPercentage < 0.4 && percentageFixed === 0.4) {
        doTrack("Position 40%");
      } else if (lastPercentage < 0.3 && percentageFixed === 0.3) {
        doTrack("Position 30%");
      } else if (lastPercentage < 0.2 && percentageFixed === 0.2) {
        doTrack("Position 20%");
      } else if (lastPercentage < 0.1 && percentageFixed === 0.1) {
        doTrack("Position 10%");
      }
      setLastPercentage(percentageFixed);
    }
  }, [progress, duration, progressIsChanging, doTrack, lastPercentage]);

  // const stopWithChromecast = useCallback(() => {
  //   if(env.startChromecastFunc && playbackUrl) {
  //     env.startChromecastFunc({url: playbackUrl, type: "application/x-mpegURL", id, src, time: playerState.latestCurrentTime});
  //   }

  //   setPlaybackState("paused");
  // }, [env?.startChromecastFunc, playbackUrl, playerState.latestCurrentTime, id, src]);

  const stopWithChromecast = () => {
    if (env.startChromecastFunc && playbackUrl) {
      env.startChromecastFunc({
        playIndex: 0,
        currentPosition: playerState.latestCurrentTime,
        items: [
          {
            contentUrl: playbackUrl,
            contentType: "application/x-mpegURL",
            streamType: asset.type,
            title: asset.name ?? "",
            subtitle: "",
            imageUrl: imageUrl,
          },
        ],
      });
    }

    setPlaybackState("paused");
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).stopWithChromecast = stopWithChromecast;

  const resumeVideo = (position: number) => {
    setSeekTo(position);
    setPlaybackState("requested-play");
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).resumeVideo = resumeVideo;

  const playVideo = () => {
    setPlaybackState("requested-play");
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).playVideo = playVideo;

  const pauseVideo = () => {
    setPlaybackState("paused");
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).pauseVideo = pauseVideo;

  // CAST START
  const loadAndPlayCurrentVideoOnCast = useCallback(
    ({ autoplay = true, currentTime = 0 }) => {
      if (cast.connected && playbackUrl) {
        const mediaInfo = new chrome.cast.media.MediaInfo(
          playbackUrl,
          "application/x-mpegURL"
        );

        const customData: AssetPlayerContext = { assetId: id, src };
        mediaInfo.customData = customData;

        const request = new chrome.cast.media.LoadRequest(mediaInfo);
        request.currentTime = currentTime;
        request.autoplay = autoplay;
        setPlaybackState("paused"); //TODO: Matthias check if this is correct
        castPlayer
          .loadMedia(request)
          .then(() => {
            if (env.isFirstTrack) {
              doTrack("Video Start");
              env.setFirstTrack(false);
            }
          })
          .catch((err) => {
            log.error(err);
          });
      }
    },
    [castPlayer, cast.connected, playbackUrl, id, src, doTrack, env]
  );

  useEffect(() => {
    if (cast.connected && playbackUrl && isActiveConferencePlayer) {
      const currentCastSrc = castPlayer.latestAssetPlayerContext?.src;
      if (!castPlayer.isMediaLoaded || src != currentCastSrc) {
        loadAndPlayCurrentVideoOnCast({
          autoplay: true,
          currentTime: playerState.latestCurrentTime,
        });
      }
    }
  }, [cast.connected, playbackUrl, isActiveConferencePlayer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      cast.connected &&
      castPlayer.isMediaLoaded &&
      castPlayer.latestAssetPlayerContext?.src === src
    ) {
      setProgress(castPlayer.currentTime);
      dispatch({
        type: "asset-update-time",
        assetId: id,
        time: castPlayer.currentTime,
      });
    }
  }, [cast.connected, castPlayer, dispatch, id, src]);

  useEffect(() => {
    if (
      cast.connected &&
      castPlayer.isMediaEnded &&
      castPlayer.latestAssetPlayerContext?.assetId === id
    ) {
      onVideoEnded();
    }
  }, [cast.connected, castPlayer, onVideoEnded, id]);

  // CAST END

  const adContainer =
    env.adMeta?.isAdfree !== true ? (
      <AdContainer
        adTags={adTags}
        playbackState={playbackState}
        requestAdState={requestAdState}
        setRequestAdState={setRequestAdState}
        setPlaybackState={setPlaybackState}
        videoRef={videoRef}
        conferenceIndex={conferenceIndex}
        assetId={id}
        isLive={isLive}
        assetFlags={assetFlags}
        currentVideoIndex={currentVideoIndex}
        videoCount={videoCount}
      />
    ) : (
      <AdContainerNoOp
        playbackState={playbackState}
        setPlaybackState={setPlaybackState}
      />
    );

  if (tokenQuery.status === "error") {
    // trigger error boundary
    if (tokenQuery.error) {
      throw tokenQuery.error;
    } else {
      throw sdtvError("Token status error", "token-invalid");
    }
  } else if (tokenQuery.status === "loading" && isActiveConferencePlayer) {
    return <LoadingIndicator imageUrl={imageUrl} />;
  }

  return tokenData ? (
    <>
      <div
        ref={videoContainerRef}
        className={`
        sdtv-player-container${isActiveConferencePlayer ? "-big" : "-small"} 
        ${className ?? ""}
        `}
        data-conference={conferenceEnv !== undefined}
        onClick={containerClicked}
        onMouseEnter={!env.isApp ? doShowControls : undefined}
        onMouseLeave={!env.isApp ? doHideControls : undefined}
      >
        <MuxVideo
          id={`sdtv-player-mux${
            conferenceEnv &&
            conferenceEnv.conferenceState.isOpen &&
            conferenceEnv.conferenceState.lastBigIndex === conferenceIndex
              ? "-active"
              : ""
          }`}
          ref={videoRef}
          playbackId={playbackId}
          streamType={isLive ? "live" : "on-demand"}
          metadata={{
            video_id: id,
            video_title: name,
          }}
          playsInline={env.isApp}
          preload="metadata"
          onLoadedMetadata={() => {
            if (playbackState === "loading") {
              setPlaybackState("idle");
            }
          }}
          onError={onVideoError}
          onEnded={onVideoEnded}
          onDurationChange={onDurationChanged}
          onTimeUpdate={onProgressChanged}
          poster={imageUrl}
          controls={false}
        />
        {adContainer}
        {showAdControls ? (
          <AdControls setRequestAdState={setRequestAdState} />
        ) : null}
        {showControls && isActiveConferencePlayer ? (
          <BigControls
            assetId={id}
            imageUrl={imageUrl}
            playbackState={playbackState}
            setPlaybackState={setPlaybackState}
            progress={progress}
            setSeekTo={setSeekTo}
            isLive={isLive}
            setHideControlsTimeout={setHideControlsTimeout}
            clearHideControlsTimeout={clearHideControlsTimeout}
            cast={cast}
            castPlayer={castPlayer}
          />
        ) : null}
        {showControls && isActiveConferencePlayer ? (
          <Controls
            showActiveAirplay={showActiveAirplay}
            playbackState={playbackState}
            progress={progress}
            setSeekTo={setSeekTo}
            setProgress={setProgress}
            setProgressIsChanging={setProgressIsChanging}
            duration={duration}
            volume={volume}
            setVolume={setVolume}
            isFullscreen={isFullscreen}
            setIsFullscreen={fullscreenCallback}
            isPip={isPip}
            setIsPip={setIsPip}
            isLive={isLive}
            setHideControlsTimeout={setHideControlsTimeout}
            showAirplay={showAirplay}
            clickAirplayCallback={clickAirplayCallback}
            cast={cast}
            castPlayer={castPlayer}
          />
        ) : null}
        {!isActiveConferencePlayer && (
          <div className="sdtv-video-title">{name}</div>
        )}
        {/* {isActiveConferencePlayer && (
          <DebugAdButtons
            playbackState={playbackState}
            debugAdState={requestAdState}
            setDebugAdState={setRequestAdState}
          />
        )} */}
      </div>
    </>
  ) : null;
}
