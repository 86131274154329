import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";

export interface VolumeState {
  volume: number;
  isMuted: boolean;
}

interface VolumeStateProps {
  isBig: boolean;
  videoRef: RefObject<HTMLVideoElement>;
}

export function useVolumeState({
  isBig,
  videoRef,
}: VolumeStateProps): [VolumeState, Dispatch<SetStateAction<VolumeState>>] {
  const [volume, setVolume] = useState<VolumeState>({
    volume: 1,
    isMuted: false,
  });

  useEffect(() => {
    setVolume((v) => {
      return { ...v, isMuted: !isBig };
    });
  }, [isBig]);

  useEffect(() => {
    videoRef.current ? (videoRef.current.volume = volume.volume) : null;
  }, [videoRef, volume.volume]);

  useEffect(() => {
    videoRef.current ? (videoRef.current.muted = volume.isMuted) : null;
  }, [videoRef, volume.isMuted]);
  return [volume, setVolume];
}
