// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function throttle<T extends any[]>(
    func: (...args: T) => void,
    delay: number,
    options: { leading?: boolean, trailing?: boolean } = {}
): (...args: T) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let lastCallTime = 0;
  let lastArgs: T | undefined;

  return function(...args: T) {
    const now = new Date().getTime();
    const isImmediate = options.leading === true && !lastCallTime;

    lastArgs = args;

    const shouldCallNow = isImmediate || now - lastCallTime >= delay;

    if (shouldCallNow) {
      lastCallTime = now;
      timeoutId && clearTimeout(timeoutId);
      timeoutId = undefined;
      func(...args);
    } else if (!timeoutId && options.trailing !== false) {
      timeoutId = setTimeout(() => {
        lastCallTime = new Date().getTime();
        timeoutId = undefined;
        if (lastArgs) {
          func(...lastArgs);
          lastArgs = undefined;
        }
      }, delay - (now - lastCallTime));
    }
  };
}
