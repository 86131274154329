import React, {createContext, useCallback, useEffect, useState} from 'react';

export interface CastContextType {
  initialized: boolean;
  connected: boolean;
  player: cast.framework.RemotePlayer | null;
  playerController: cast.framework.RemotePlayerController | null;
  deviceName: string;
}

const Context = createContext<CastContextType>({initialized: false, connected: false, player: null, playerController: null, deviceName: ""});

export default function CastProvider({children}: {children: React.ReactNode}) {
  const [connected, setConnected] = useState<boolean>(false);
  const [deviceName, setDeviceName] = useState<string>('');
  const [initialized] = useState<boolean>(false);
  const [player, setPlayer] = useState<cast.framework.RemotePlayer | null>(
      null
  );
  const [
    playerController,
    setPlayerController
  ] = useState<cast.framework.RemotePlayerController | null>(null);

  // enable cast framework
  // useEffect(() => {
  //   CastLoader.load().then(() => {
  //     setInitialized(true);
  //     log.debug('Cast initialized');
  //   }).catch(() => {
  //     log.error("CastProvider", "Failed to load cast framework");
  //   });
  // }, []);


  const resetCast = useCallback(() => {
    setConnected(false);
    setDeviceName('');
  }, []);


  useEffect(() => {
    function onSessionStateChange(data: cast.framework.SessionStateEventData) {
      if (
          data.sessionState ===
          window.cast.framework.SessionState.SESSION_RESUMED ||
          data.sessionState === window.cast.framework.SessionState.SESSION_STARTED
      ) {
        setConnected(true);
      }
      if (data.sessionState === window.cast.framework.SessionState.SESSION_ENDED) {
        resetCast();
        setConnected(false);
      }
    }

    if ((window.chrome && window.chrome.cast) && window.cast) {

      window.cast.framework.setLoggerLevel(window.cast.framework.LoggerLevel.DEBUG);

      window.cast.framework.CastContext.getInstance().setOptions({
        receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
        autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        // resumeSavedSession,
        // language
      });
      const player = new window.cast.framework.RemotePlayer();
      setPlayer(player);
      setPlayerController(new window.cast.framework.RemotePlayerController(player));

      window.cast.framework.CastContext.getInstance().addEventListener(
          window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
          onSessionStateChange
      );
    }
    return () => {
      if ((window.chrome && window.chrome.cast) && window.cast) {
        window.cast.framework.CastContext.getInstance().removeEventListener(
            window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
            onSessionStateChange
        );
      }
    }
  }, [
    initialized,
    resetCast
  ]);

  useEffect(() => {
    const onConnectedChange = (
        _data: cast.framework.RemotePlayerChangedEvent
    ) => {
      if(_data.value){

        setConnected(true);
        const session = window.cast.framework.CastContext.getInstance().getCurrentSession();
        if (session) {
          setDeviceName(session.getSessionObj().receiver.friendlyName);
        }
      } else {
        setConnected(false);
      }
    };
    if (playerController) {
      playerController.addEventListener(
          window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
          onConnectedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
            window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
            onConnectedChange
        );
      }
    };
  }, [playerController]);

  return <Context.Provider value={{initialized, connected, player, playerController, deviceName}}>{children}</Context.Provider>;
}


export function useCast() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error("useCast must be used within a CastProvider");
  }

  return context;
}
