import {useMemo, useCallback, useState, useEffect, useRef} from 'react';
import {useCast} from "./CastProvider";

export interface CastPlayer {
  loadMedia: (request: chrome.cast.media.LoadRequest) => Promise<void>;
  currentTime: number;
  duration: number;
  togglePlay: () => void;
  seek: (time: number) => void;
  isMediaLoaded: boolean,
  isMediaEnded: boolean,
  isPaused: boolean,
  // isMuted: boolean,
  latestAssetPlayerContext: AssetPlayerContext|null,
}

export interface AssetPlayerContext {
  assetId: string;
  src: string;
}

export default function useCastPlayer(): CastPlayer {
  const { connected, player, playerController } = useCast();
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false);
  const [isMediaEnded, setIsMediaEnded] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  // const [isMuted, setIsMuted] = useState<boolean>(false);
  const [latestMediaLoadRequest, setLatestMediaLoadRequest] = useState<chrome.cast.media.LoadRequest|null>(null);
  const [latestAssetPlayerContext, setLatestAssetPlayerContext] = useState<AssetPlayerContext|null>(null);
  const latestMediaSession = useRef<chrome.cast.media.Media|null>(null);

  function resetValues() {
    setCurrentTime(0);
    setDuration(0);
    setIsMediaLoaded(false);
    setIsPaused(false);
    // setIsMuted(false);
    setLatestMediaLoadRequest(null);
    latestMediaSession.current = null;
  }

  useEffect(() => {
    if (!connected) {
      resetValues();
    }
  }, [connected]);

  // CURRENT_TIME_CHANGED
  useEffect(() => {
    function handleCurrentTimeChange(data: cast.framework.RemotePlayerChangedEvent<number>) {
      setCurrentTime(data.value);
    }

    if (playerController) {
      playerController.addEventListener(
          window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
          handleCurrentTimeChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
            window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
            handleCurrentTimeChange
        );
      }
    };
  }, [playerController, setCurrentTime]);

  // DURATION_CHANGED
  useEffect(() => {
    function handleDurationChange(data: cast.framework.RemotePlayerChangedEvent<number>) {
      setDuration(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
          window.cast.framework.RemotePlayerEventType.DURATION_CHANGED,
          handleDurationChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
            window.cast.framework.RemotePlayerEventType.DURATION_CHANGED,
            handleDurationChange
        );
      }
    };
  }, [playerController, setDuration]);

  //IS_MEDIA_LOADED_CHANGED
  useEffect(() => {
    function handleMediaLoadedChange(data: cast.framework.RemotePlayerChangedEvent<boolean>) {
      setIsMediaLoaded(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
          window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
          handleMediaLoadedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
            window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
            handleMediaLoadedChange
        );
      }
    };
  }, [playerController, setIsMediaLoaded, isMediaLoaded]);

  // IS_PAUSED_CHANGED
  useEffect(() => {
    function handleIsPausedChange(data: cast.framework.RemotePlayerChangedEvent<boolean>) {
      setIsPaused(data.value);
    }
    if (playerController) {
      playerController.addEventListener(
          window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
          handleIsPausedChange
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
            window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
            handleIsPausedChange
        );
      }
    };
  }, [playerController, setIsPaused]);


  // MEDIA_INFO_CHANGED
  useEffect(() => {
    function onMediaInfoChanged(data: cast.framework.RemotePlayerChangedEvent<chrome.cast.media.MediaInfo>) {
      if(data.value?.customData){
        const assetPlayerContext = data.value?.customData as AssetPlayerContext;
        setLatestAssetPlayerContext(assetPlayerContext);
      }

      if(!latestMediaSession.current){
        const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
        if(castSession){
          const mediaSession = castSession.getMediaSession();
          if(mediaSession){
            latestMediaSession.current = castSession.getMediaSession();
          }
        }
      }

      const mediaSession = latestMediaSession.current;
      if(mediaSession && mediaSession.playerState === chrome.cast.media.PlayerState.IDLE && mediaSession?.idleReason === chrome.cast.media.IdleReason.FINISHED){
        setIsMediaEnded(true);
      }

    }

    if (playerController) {
      playerController.addEventListener(
          window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
          onMediaInfoChanged
      );
    }
    return () => {
      if (playerController) {
        playerController.removeEventListener(
            window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
            onMediaInfoChanged
        );
      }
    };
  }, [
    playerController,
  ]);

  const loadMedia = useCallback((request: chrome.cast.media.LoadRequest) => {
    setLatestMediaLoadRequest(request);

    const castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();

    if (castSession) {

      return castSession.loadMedia(request).then(() => {
        setIsMediaEnded(false);
        latestMediaSession.current = castSession.getMediaSession();
      });
    } else {
      return Promise.reject('No CastSession has been created');
    }
  }, []);

  const togglePlay = useCallback(() => {
    if (playerController) {
      // playerController.playOrPause();
      if(isMediaLoaded){
        return playerController.playOrPause();
      } else if(latestMediaLoadRequest){
        const request = new chrome.cast.media.LoadRequest(latestMediaLoadRequest.media);
        request.autoplay = true;
        return loadMedia(request);
      }
    }
  }, [playerController, isMediaLoaded, latestMediaLoadRequest, loadMedia]);


  const seek = useCallback(
      (time: number) => {
        if (player && playerController) {
          player.currentTime = time;
          playerController.seek();
        }
      },
      [player, playerController]
  );

  //IS_MUTED_CHANGED

  // useEffect(() => {
  //   function handleIsMutedChange(data: cast.framework.RemotePlayerChangedEvent<boolean>) {
  //     setIsMuted(data.value);
  //   }
  //   if (playerController) {
  //     playerController.addEventListener(
  //         window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
  //         handleIsMutedChange
  //     );
  //   }
  //   return () => {
  //     if (playerController) {
  //       playerController.removeEventListener(
  //           window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
  //           handleIsMutedChange
  //       );
  //     }
  //   };
  // }, [playerController, setIsMuted]);

  // PLAYER_STATE_CHANGED
  // useEffect(() => {
  //   function handlePlayerStateChanged(data: cast.framework.RemotePlayerChangedEvent<chrome.cast.media.PlayerState>) {
  //     console.log('player state changed', data);
  //   }
  //
  //   if (playerController) {
  //     playerController.addEventListener(
  //         window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
  //         handlePlayerStateChanged
  //     );
  //   }
  //   return () => {
  //     if (playerController) {
  //       playerController.removeEventListener(
  //           window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
  //           handlePlayerStateChanged
  //       );
  //     }
  //   };
  // }, [playerController]);


  // const toggleMute = useCallback(() => {
  //   if (playerController) {
  //     playerController.muteOrUnmute();
  //   }
  // }, [playerController]);


  // const setVolume = useCallback(
  //     (volume: ) => {
  //       if (player && playerController) {
  //         player.volumeLevel = volume;
  //         playerController.setVolumeLevel();
  //       }
  //     },
  //     [player, playerController]
  // );


  const value = useMemo(
      () => ({
        loadMedia,
        currentTime,
        duration,
        // toggleMute,
        // setVolume,
        togglePlay,
        seek,
        isMediaLoaded,
        isMediaEnded,
        isPaused,
        // isMuted,
        latestAssetPlayerContext,
        // thumbnail
      }),
      [
        loadMedia,
        // tracks,
        // editTracks,
        currentTime,
        duration,
        // toggleMute,
        // setVolume,
        togglePlay,
        seek,
        isMediaLoaded,
        isMediaEnded,
        isPaused,
        // isMuted,
        latestAssetPlayerContext
        // thumbnail
      ]
  );
  return value;
}


