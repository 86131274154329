import React from "react";
import Bugsnag, { Event } from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";
import {
  apiErrorMessageToErrorState,
  sendErrorStateToBugsnag,
} from "../../shared/errorStates";

Bugsnag.start({
  apiKey: "27f753b96fb9b2c28711db8f78f4fe57",
  plugins: [new BugsnagPluginReact()],
  appVersion: "0.10",
  autoTrackSessions: false,
  collectUserIp: false,
  generateAnonymousId: false,
  enabledReleaseStages: ["production"],
  onError: function (event: Event) {
    if (event.context) {
      const context_sections = event.context.split("RunnerProd.app");

      if (context_sections.length > 0) {
        event.context = context_sections[context_sections.length - 1];
      }
    }

    const errorState = apiErrorMessageToErrorState(
      event.errors[0].errorMessage
    );
    // only send certain api errors to bugsnag
    return !errorState || sendErrorStateToBugsnag(errorState);
  },
});

export const ErrorBoundary = (
  Bugsnag.getPlugin("react") as BugsnagPluginReactResult
).createErrorBoundary(React);
