import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { sdtvError } from "./components/common/SdtvPlayerError";
import { AdMeta } from "./model/AdMeta";
import { ChromecastInfos } from "./model/ChromecastInfos";
import { TrackAction } from "./shared/trackingActions";

export interface EnvironmentProps {
  isApp: boolean;
  bearerToken?: string;
  adMeta?: AdMeta;
  stop: boolean;
  setIsAirplayActive: Dispatch<SetStateAction<boolean>>
  isPiPActive: boolean;
  setIsPiPActive: Dispatch<SetStateAction<boolean>>
  stopVideo: () => void;
  resumeVideo: () => void;
  trackingFunc: (action: TrackAction, additionalInfo?: string) => void;
  onFullscreenBtnFunc?: (value: boolean) => void;
  onConfAssetChangedFunc?: (index: number) => void;
  onControlsVisibilityFunc?: (value: boolean) => void;
  adsFinishedFunc?: () => void;
  startChromecastFunc?: (chromecastInfos: ChromecastInfos) => void;
  host: string;
  imageHost: string;
}

interface ExtendedEnvironmentProps extends EnvironmentProps {
  isFullscreen: boolean;
  setFullscreen: Dispatch<SetStateAction<boolean>>;
  isFirstTrack: boolean;
  setFirstTrack: Dispatch<SetStateAction<boolean>>;
}

const EnvironmentContext = React.createContext<
  ExtendedEnvironmentProps | undefined
>(undefined);

export function Environment({
  children,
  ...envProps
}: PropsWithChildren<EnvironmentProps>) {
  const [isFullscreen, setFullscreen] = useState(false);
  const [isFirstTrack, setFirstTrack] = useState(true);
  return (
    <EnvironmentContext.Provider
      value={{
        ...envProps,
        isFullscreen,
        setFullscreen,
        isFirstTrack,
        setFirstTrack,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
}

export function useEnvironment() {
  const env = useContext(EnvironmentContext);
  if (env) {
    return env;
  } else {
    throw sdtvError("Environment not initialized", "default");
  }
}
