import { ErrorState } from "../../shared/errorStates";

export interface SdtvPlayerError extends Error {
  state: ErrorState;
}

export const sdtvError = (message: string, state: ErrorState) => {
  const error: SdtvPlayerError = {
    name: "SdtvPlayerError",
    message,
    state,
  };
  return error;
};
