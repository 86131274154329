import {createContext, Dispatch, useContext, useEffect, useReducer} from 'react';
import log from "loglevel";
import throttle from "./shared/throttle";

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

const PlayerStateContext = createContext<PlayerState|undefined>(undefined);

const PlayerDispatchContext = createContext<Dispatch<PlayerAction>|undefined>(undefined);

interface PlayerState {
  assets:  {
    [assetId: string]: AssetState
  };
  livePrerollPlayedAt?: number;
}
interface AssetState {
  latestCurrentTime: number;
  currentVideoIndex: number;
  ended: boolean;
  updatedAt: number;
  prerollPlayedAt?: number;
}

const initialPlayerState = {
  assets: {},
  livePrerollPlayedAt: 0
};
const initialAssetState = {
  latestCurrentTime: 0,
  currentVideoIndex: 0,
  ended: false,
  updatedAt: 0
};

const throttledSaveToLocalStorage = throttle((state: PlayerState) => {
  try {
    //TODO: what exactly should we store? all? partial fields? decide in the future when we have moved all state here what makes sense
    window.localStorage.setItem(`stdv-player-state-2`, JSON.stringify(state))
  } catch (error) {
    log.error("savePlayerState", error);
  }
}, 3000, {leading: true, trailing: true});

export function PlayerStateProvider({ children }: {children: React.ReactNode }) {
  const [state, dispatch] = useReducer(
      playerStateReducer,
      initialPlayerState,
      (initialState) => {
        try {
          const item = window.localStorage.getItem(`stdv-player-state-2`);
          if (item) {
            const savedState = JSON.parse(item) as PlayerState;
            const newState = initialState
            for (const [key, value] of Object.entries(savedState.assets)) {
              //ignore old state
              if (value.updatedAt + ONE_WEEK < Date.now()) {
                continue;
              }
              newState.assets[key] = {...initialAssetState, ...value};
            }
            if(savedState && savedState.livePrerollPlayedAt && savedState.livePrerollPlayedAt > 0) {
              newState.livePrerollPlayedAt = savedState.livePrerollPlayedAt;
            }
            return newState;
          }
        } catch (error) {
          log.error("loadPlayerState", error);
        }
        return initialState;
      }
  );

  useEffect(() => {
    throttledSaveToLocalStorage(state);
  }, [state]);

  return (
      <PlayerStateContext.Provider value={state}>
        <PlayerDispatchContext.Provider value={dispatch}>
          {children}
        </PlayerDispatchContext.Provider>
      </PlayerStateContext.Provider>
  );
}

export function usePlayerState() {
  const context = useContext(PlayerStateContext);
  if (context === undefined) {
    throw new Error(`usePlayerState must be used within a PlayerStateProvider`);
  }
  return context;
}

export function useAssetPlayerState(assetId: string) {
  const context = useContext(PlayerStateContext);
  if (context === undefined) {
    throw new Error(`useAssetPlayerState must be used within a PlayerStateProvider`);
  }
  return context.assets[assetId] || initialAssetState;
}

export function usePlayerStateDispatch() {
  const context = useContext(PlayerDispatchContext);
  if (context === undefined) {
    throw new Error(`usePlayerStateDispatch must be used within a PlayerStateProvider`);
  }
  return context;
}

// export function useAssetPlayerStateDispatch(assetId: string) {
//   const dispatch = useContext(PlayerDispatchContext);
//   if (dispatch === undefined) {
//     throw new Error(`usePlayerStateDispatch must be used within a PlayerStateProvider`);
//   }
//   return (action: AssetPlayerActionTest) => dispatch({...action, assetId} as AssetPlayerAction);
// }


interface BaseAssetAction {
  assetId: string;
}

interface AssetUpdateCurrentTimeAction extends BaseAssetAction {
  type: "asset-update-time"
  time: number
}

interface AssetNextPlaylistVideoAction extends BaseAssetAction {
  type: "asset-next-playlist-video"
}

interface AssetRestartVideoAction extends BaseAssetAction {
  type: "asset-restart"
}

interface AssetResetAction extends BaseAssetAction {
  type: "asset-reset"
}

interface AssetEndedAction extends BaseAssetAction {
  type: "asset-ended"
}

interface AssetPrerollPlayedAction extends BaseAssetAction {
  type: "asset-preroll-played"
}

interface LivePrerollPlayedAction {
  type: "live-preroll-played"
}

type PlayerAction = AssetUpdateCurrentTimeAction | AssetNextPlaylistVideoAction | AssetRestartVideoAction | AssetResetAction | AssetEndedAction | AssetPrerollPlayedAction | LivePrerollPlayedAction;


// Externally-visible signature
function assertUnreachable(a: never): never;
// Implementation signature
function assertUnreachable(a: PlayerAction) {
  throw new Error('Unknown action: ' + a.type);
}

function playerStateReducer(state: PlayerState, action: PlayerAction) {
  // let newState = state;
  switch (action.type) {
    case 'asset-update-time':
    case 'asset-next-playlist-video':
    case 'asset-restart':
    case 'asset-reset':
    case 'asset-ended':
    case 'asset-preroll-played':
      return {...state, assets: {...state.assets, [action.assetId]: {...assetStateReducer(state.assets[action.assetId] || initialAssetState, action), updatedAt: Date.now()}}};      
    case 'live-preroll-played':
      return {...state, livePrerollPlayedAt: Date.now()};
    default:
      assertUnreachable(action);
  }
  // return {newState, updatedAt: Date.now()};
}

function assetStateReducer(state: AssetState, action: PlayerAction) {
  switch (action.type) {
    case 'asset-update-time':
      return {...state, latestCurrentTime: action.time}
    case 'asset-next-playlist-video':
      return {...state, currentVideoIndex: state.currentVideoIndex + 1, latestCurrentTime: 0}
    case 'asset-restart':
      return {...state, currentVideoIndex: 0, latestCurrentTime: 0, ended: false}
    case 'asset-ended':
      return {...state, ended: true}
    case 'asset-preroll-played':
      return {...state, prerollPlayedAt: Date.now()}
    case 'asset-reset':
      return initialAssetState;
    default: {
      return state;
    }
  }
}
