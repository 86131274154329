import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { loadImaSdk, google } from "@alugha/ima";
import { PlaybackState } from "../shared/videoStates";
import { useEnvironment } from "../Environment";
import { RequestAdState } from "../shared/adStates";
import { sdtvError } from "../components/common/SdtvPlayerError";
type ImaSdk = typeof google.ima;

export function useImaAdRequest(
  playbackState: PlaybackState,
  setPlaybackState: Dispatch<PlaybackState>,
  setRequestAdState: Dispatch<SetStateAction<RequestAdState>>,
  videoRef: RefObject<HTMLVideoElement>,
  adContainerRef: RefObject<HTMLDivElement>
): [boolean, (adtagUrl: string) => void, () => void, () => void, () => void] {
  const sdkRef = useRef<ImaSdk>();
  const adsManagerRef = useRef<google.ima.AdsManager>();
  const [adSdkLoading, setAdSdkLoading] = useState(false);
  const [adSdkLoaded, setAdSdkLoaded] = useState(false);

  const [simpleResized, setSimpleResized] = useState(false);

  const onContentPauseRequest = useCallback(
    () => setPlaybackState("requested-ads"),
    [setPlaybackState]
  );
  const onContentResumeRequest = useCallback(
    () => setPlaybackState("ads-finished"),
    [setPlaybackState]
  );
  const adFinished = useCallback(() => {
    setRequestAdState((oldState: RequestAdState) => {
      if (oldState === "request-postroll") {
        setPlaybackState("ended");
      } else {
        setPlaybackState("ads-finished");
      }
      return "initialized";
    });
  }, [setPlaybackState, setRequestAdState]);
  const onAdsCompleted = useCallback(() => {
    if (adsManagerRef.current) {
      console.log("ima ad complete");
      adsManagerRef.current.stop();
      adsManagerRef.current.destroy();
      adsManagerRef.current = undefined;
      adFinished();
      if (adContainerRef.current) {
        adContainerRef.current.innerHTML = "";
      }
    }
  }, [adContainerRef, adFinished]);
  const pauseAdCallback = useCallback(() => {
    if (adsManagerRef.current) {
      console.log("ima ad paused");
      adsManagerRef.current.pause();
    }
  }, []);
  const skipAdCallback = useCallback(() => {
    if (adsManagerRef.current) {
      console.log("ima ad skipped");
      adsManagerRef.current.stop();
    }
  }, []);
  const resumeAdCallback = useCallback(() => {
    if (adsManagerRef.current) {
      console.log("ima ad resumed");
      adsManagerRef.current.resume();
      setRequestAdState("running");
    }
  }, [setRequestAdState]);
  const onAdsManagerLoaded = useCallback(
    (adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent) => {
      if (sdkRef.current && videoRef.current) {
        console.log("ad manager loaded");
        const videoElement = videoRef.current;
        const adsManager = adsManagerLoadedEvent.getAdsManager(videoElement);

        const width = videoElement.clientWidth;
        const height = videoElement.clientHeight;

        try {
          adsManager.init(width, height, sdkRef.current.ViewMode.NORMAL);
          adsManager.addEventListener(
            sdkRef.current.AdEvent.Type.ALL_ADS_COMPLETED,
            onAdsCompleted,
            false
          );
          adsManager.addEventListener(
            sdkRef.current.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
            onContentPauseRequest
          );
          adsManager.addEventListener(
            sdkRef.current.AdEvent.Type.CONTENT_RESUME_REQUESTED,
            onContentResumeRequest
          );
          adsManager.start();
        } catch (adError) {
          // Play the video without ads, if an error occurs
          console.log("AdsManager could not be started");
          setPlaybackState("ads-finished");
          setRequestAdState("initialized");
        } finally {
          adsManagerRef.current = adsManager;
        }
      }
    },
    [
      onAdsCompleted,
      onContentPauseRequest,
      onContentResumeRequest,
      setPlaybackState,
      setRequestAdState,
      videoRef,
    ]
  );
  const onAdError = useCallback(
    (adErrorEvent: google.ima.AdErrorEvent) => {
      console.log(adErrorEvent.getError());
      if (adsManagerRef.current) {
        adsManagerRef.current.destroy();
        adsManagerRef.current = undefined;
      }
      adFinished();
    },
    [adFinished]
  );
  const env = useEnvironment();
  const loadAdCallback = useCallback(
    (adTagUrl: string) => {
      if (adTagUrl == null || adTagUrl.length == 0) {
        adFinished();
      } else if (sdkRef.current && adContainerRef.current && videoRef.current) {
        console.log("load ad...");
        if (env.isApp) {
          sdkRef.current.settings.setDisableCustomPlaybackForIOS10Plus(true);
        }
        sdkRef.current.settings.setVpaidMode(
          // according to teraone this is a desired XSS vulnerability...
          sdkRef.current.ImaSdkSettings.VpaidMode.INSECURE
        );
        const adDisplayContainer: google.ima.AdDisplayContainer =
          new sdkRef.current.AdDisplayContainer(
            adContainerRef.current,
            videoRef.current
          );
        adDisplayContainer.initialize();
        const adsLoader: google.ima.AdsLoader = new sdkRef.current.AdsLoader(
          adDisplayContainer
        );
        const adRequest = _buildAdRequest(
          sdkRef.current,
          adTagUrl,
          videoRef.current
        );
        adsLoader.requestAds(adRequest);
        adsLoader.addEventListener(
          sdkRef.current.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
          onAdsManagerLoaded,
          false
        );
        adsLoader.addEventListener(
          sdkRef.current.AdErrorEvent.Type.AD_ERROR,
          onAdError,
          false
        );
      } else {
        throw sdtvError(
          "adContainer and video both must not be null!",
          "player-error"
        );
      }
    },
    [
      adContainerRef,
      adFinished,
      env.isApp,
      onAdError,
      onAdsManagerLoaded,
      videoRef,
    ]
  );
  const loadSdkCallback = useCallback(() => {
    if (!adSdkLoading && adContainerRef.current && videoRef.current) {
      setAdSdkLoading(true);
      console.log("load sdk...");
      loadImaSdk()
        .then((ima) => {
          sdkRef.current = ima;
          setAdSdkLoaded(true);
          setPlaybackState("requested-play");
        })
        .catch((e) => {
          console.log(e);
          console.log("Cannot initialize ImaSdk!");
          setAdSdkLoaded(false);
          setAdSdkLoading(false);
          setPlaybackState("ads-finished");
          setRequestAdState("initialized");
        });
    }
  }, [
    adContainerRef,
    adSdkLoading,
    setPlaybackState,
    setRequestAdState,
    videoRef,
  ]);

  useEffect(() => {
    if (playbackState === "init-ads" && !adSdkLoaded) {
      loadSdkCallback();
    }
  }, [loadSdkCallback, playbackState, adSdkLoaded]);

  let resizeObserver = undefined;
  if(window.ResizeObserver != undefined) {
    resizeObserver =new ResizeObserver(() => {
      if (adsManagerRef.current && videoRef.current && sdkRef.current) {
        // console.log(
        //   "actual window resize, videoWidth:",
        //   videoRef.current.clientWidth,
        //   "window width",
        //   window.innerWidth
        // );
        const width = videoRef.current.clientWidth;
        const height = videoRef.current.clientHeight;
        adsManagerRef.current.resize(
          width,
          height,
          sdkRef.current.ViewMode.FULLSCREEN
        );
      }
    });
  }

  if (videoRef.current) {
    if(resizeObserver != undefined) {
      resizeObserver.observe(videoRef.current);
    }
    else if(!simpleResized && adsManagerRef.current && videoRef.current && sdkRef.current) {
      const width = videoRef.current.clientWidth;
      const height = videoRef.current.clientHeight;
      adsManagerRef.current.resize(
        width,
        height,
        sdkRef.current.ViewMode.FULLSCREEN
      );
      setSimpleResized(true)
    }
  }

  // videoRef.current?.addEventListener("onresize", function () {
  //   console.log("window resize");
  //   if (adsManagerRef.current && videoRef.current && sdkRef.current) {
  //     console.log(
  //       "actual window resize, videoWidth:",
  //       videoRef.current.clientWidth,
  //       "window width",
  //       window.innerWidth
  //     );
  //     const width = videoRef.current.clientWidth;
  //     const height = videoRef.current.clientHeight;
  //     adsManagerRef.current.resize(
  //       width,
  //       height,
  //       sdkRef.current.ViewMode.FULLSCREEN
  //     );
  //   }
  // });

  return [
    adSdkLoaded,
    loadAdCallback,
    skipAdCallback,
    pauseAdCallback,
    resumeAdCallback,
  ];
}

function _buildAdRequest(
  ima: ImaSdk,
  adTagUrl: string,
  videoElement: HTMLVideoElement
) {
  const adsRequest: google.ima.AdsRequest = new ima.AdsRequest();
  adsRequest.omidAccessModeRules = {};
  adsRequest.omidAccessModeRules[ima.OmidVerificationVendor.GOOGLE] =
    ima.OmidAccessMode.FULL;
  adsRequest.omidAccessModeRules[ima.OmidVerificationVendor.OTHER] =
    ima.OmidAccessMode.FULL;
  adsRequest.adTagUrl = adTagUrl;
  console.log("building adrequest for adtagurl: ", adTagUrl);

  // TOOD: check if we need these

  // Specify the linear and nonlinear slot sizes. This helps the SDK to
  // select the correct creative if multiple are returned.
  adsRequest.linearAdSlotWidth = videoElement.clientWidth;
  adsRequest.linearAdSlotHeight = videoElement.clientHeight;
  adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
  adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;
  return adsRequest;
}
