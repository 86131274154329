import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useConstructAdTags } from "../../ads/useConstructAdTags";
import { useImaAdRequest } from "../../ads/useImaSdk";
import { useGetMidroll } from "../../api/useGetMidroll";
import { AdTags } from "../../model/AdTags";
import { AssetFlags } from "../../model/Asset";
import { RequestAdState } from "../../shared/adStates";
import { PlaybackState } from "../../shared/videoStates";
import { useConferenceEnvironment } from "../ConferenceEnvironment";
import log from "loglevel";
import {
  useAssetPlayerState,
  usePlayerState,
  usePlayerStateDispatch,
} from "../../PlayerStateContext";
import { useEnvironment } from "../../Environment";

interface AdContainerProps {
  adTags?: AdTags;
  playbackState: PlaybackState;
  requestAdState: RequestAdState;
  setRequestAdState: Dispatch<SetStateAction<RequestAdState>>;
  setPlaybackState: Dispatch<PlaybackState>;
  videoRef: RefObject<HTMLVideoElement>;
  conferenceIndex?: number;
  assetId: string;
  isLive: boolean;
  assetFlags: AssetFlags[];
  currentVideoIndex: number;
  videoCount: number;
}

const FIVE_MIN = 1000 * 60 * 5;

function prerollPlayedLastFiveMinutes(prerollPlayedAt?: number) {
  if (!prerollPlayedAt) {
    return false;
  }
  return prerollPlayedAt + FIVE_MIN > Date.now();
}


function prerollPlayedLastTenMinutes(prerollPlayedAt?: number) {
  if (!prerollPlayedAt) {
    return false;
  }
  return prerollPlayedAt + (FIVE_MIN * 2) > Date.now();
}

export function AdContainer({
  adTags,
  playbackState,
  requestAdState,
  setRequestAdState,
  setPlaybackState,
  videoRef,
  assetId,
  isLive,
  assetFlags,
  currentVideoIndex,
  videoCount,
  // only in liveconference
  conferenceIndex,
}: AdContainerProps) {
  const adContainerRef = useRef<HTMLDivElement>(null);
  // time in seconds the video this adcontainer belongs to was playing (not ads, paused etc)
  const [runtime, setRuntime] = useState(0);
  const midrollIndex = useRef(0);
  const midrollBlockDuration = useRef<number>();
  const [lastMidrollPoint, setLastMidrollPoint] = useState(0);
  const [noMoreMidrolls, setNoMoreMidrolls] = useState(
    assetFlags.includes("NO_ADS") || assetFlags.includes("NO_MIDROLL")
      ? true
      : false
  );

  const runtimeIntervalRef = useRef<number | undefined>(undefined);
  useEffect(() => {
    if (playbackState === "playing") {
      runtimeIntervalRef.current = setInterval(() => {
        setRuntime((oldValue: number) => oldValue + 1);
      }, 1000) as unknown as number;
    } else {
      if (runtimeIntervalRef.current !== undefined) {
        clearInterval(runtimeIntervalRef.current);
      }
    }
  }, [playbackState]);

  // undefined if not in liveconference
  const conferenceEnv = useConferenceEnvironment();
  const setConferenceState = conferenceEnv?.setConferenceState;

  const assetPlayerState = useAssetPlayerState(assetId);
  const playerState = usePlayerState();
  const dispatch = usePlayerStateDispatch();

  const isSkipPreroll = isLive ? prerollPlayedLastTenMinutes(playerState.livePrerollPlayedAt) : prerollPlayedLastFiveMinutes(assetPlayerState.prerollPlayedAt);

  const [showPreroll, setShowPreroll] = useState(
    assetFlags.includes("NO_ADS") ||
      assetFlags.includes("NO_PREROLL") ||
      isSkipPreroll
      ? false
      : currentVideoIndex % 5 === 0
  );

  // console.log('adcontainer', assetId, 'prerollplaedat', assetPlayerState.prerollPlayedAt, 'skip', isSkipPreroll, 'show', showPreroll)

  const showPostroll = assetFlags.includes("NO_ADS") // || assetFlags.includes("NO_POSTROLL") ???
    ? false
    : currentVideoIndex + 1 === videoCount;
  const [preRollUrl, getMidRollUrl, postRollUrl] = useConstructAdTags(
    adTags,
    isLive
  );
  const [
    adSdkLoadedState,
    loadAdCallback,
    skipAdCallback,
    pauseAdCallback,
    resumeAdCallback,
  ] = useImaAdRequest(
    playbackState,
    setPlaybackState,
    setRequestAdState,
    videoRef,
    adContainerRef
  );

  const startMidroll = useCallback(() => {
    log.debug("external midroll trigger", conferenceIndex);
    // if (conferenceIndex === index) {
    setRequestAdState("request-midroll");
    // }
  }, [setRequestAdState, conferenceIndex]);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).startMidroll[conferenceIndex ?? 0] = startMidroll;

  const env = useEnvironment();
  const firstMidrollStart = env.adMeta?.firstRequestSeconds ?? 10 * 60;
  const subsequentMidrollStart =
    env.adMeta?.subsequentRequestSeconds ?? 17 * 60;
  useEffect(() => {
    // midrolls are triggered after 10 and then every 17 minutes in on demand
    if (!isLive) {
      if (
        (lastMidrollPoint === 0 &&
          runtime - lastMidrollPoint > firstMidrollStart) ||
        runtime - lastMidrollPoint > subsequentMidrollStart
      ) {
        // if (runtime - lastMidrollPoint > 1 * 60) {
        // only for debug purposes trigger it after one minute
        // TODO: check feed if we are allowed to midroll?
        log.debug("ondemand video midroll intervall triggered:");
        setLastMidrollPoint(runtime);
        startMidroll();
      }
    }
  }, [
    firstMidrollStart,
    isLive,
    lastMidrollPoint,
    runtime,
    startMidroll,
    subsequentMidrollStart,
  ]);

  const midrollFeed = useGetMidroll({
    assetId: assetId,
    enabled: isLive && !noMoreMidrolls,
    intervall: env.adMeta?.liveFetchIntervall,
  });
  useEffect(() => {
    if (midrollFeed.data && midrollFeed.data.status_code === 204) {
      setNoMoreMidrolls(true);
    }
  }, [midrollFeed]);
  useEffect(() => {
    // midrolls are triggered in livestreams every time the feed says they should
    if (
      isLive &&
      adSdkLoadedState &&
      !noMoreMidrolls &&
      midrollFeed.data?.body &&
      midrollFeed.data?.body[0] &&
      midrollFeed.data?.body[0]?.id
    ) {
      log.debug("live video midroll feed response: ", midrollFeed.data.body);
      midrollBlockDuration.current =
        midrollFeed.data.body[0].duration_in_seconds;
      startMidroll();
    }
  }, [
    isLive,
    adSdkLoadedState,
    midrollFeed.data,
    noMoreMidrolls,
    startMidroll,
  ]);

  useEffect(() => {
    // log.debug(
    //   "conf ad state effect",
    //   conferenceEnv?.conferenceState,
    //   "index",
    //   conferenceIndex
    // );
    // this is called a lot (in every video every time conferenceEnv changes!), but we only handle the queue events here and ignore everything else
    if (conferenceEnv && conferenceIndex !== undefined) {
      switch (conferenceEnv.conferenceState.adState) {
        case "queue-complete": {
          if (conferenceEnv.conferenceState.bigIndex === conferenceIndex) {
            // only do this once (in the big video == which played the ad)
            setPlaybackState("all-ads-finished");
            setRequestAdState("initialized");
            conferenceEnv?.setConferenceState((oldState) => {
              return { ...oldState, adState: "all-ads-complete" };
            });
          }
          break;
        }
        case "next-ad": {
          if (conferenceEnv.conferenceState.adQueue.length > 0) {
            const nextAd = conferenceEnv.conferenceState.adQueue[0];
            // only do this once (in the video which shall play the ad)
            if (conferenceIndex == nextAd.conferenceIndex) {
              if (!adSdkLoadedState) {
                // once adsdk is loaded we come here again
                setPlaybackState("init-ads");
              } else {
                loadAdCallback(nextAd.adTagUrl);
                conferenceEnv?.setConferenceState((oldState) => {
                  return {
                    ...oldState,
                    bigIndex: nextAd.conferenceIndex,
                    isOpen: false,
                    showControls: false,
                    adState: "ad-running",
                  };
                });
              }
            }
          } else {
            conferenceEnv?.setConferenceState((oldState) => {
              return { ...oldState, adState: "queue-complete" };
            });
          }
          break;
        }
      }
    }
  }, [
    adSdkLoadedState,
    conferenceEnv,
    loadAdCallback,
    setConferenceState,
    setPlaybackState,
    setRequestAdState,
    conferenceIndex,
  ]);

  useEffect(() => {
    // log.debug("playback state effect", playbackState, "index", conferenceIndex);
    switch (playbackState) {
      case "requested-ads":
        if (setConferenceState && conferenceIndex !== undefined) {
          setConferenceState((oldState) => {
            if (conferenceIndex === oldState.bigIndex) {
              return {
                ...oldState,
                isOpen: false,
                showControls: false,
                adState: "ad-running",
              };
            } else return oldState;
          });
        }
        setRequestAdState("running");
        break;
      case "ads-finished":
        if (setConferenceState && conferenceIndex !== undefined) {
          setConferenceState((oldState) => {
            if (oldState.adQueue.length === 0) {
              return {
                ...oldState,
                adState: "queue-complete",
              };
            } else if (oldState.adQueue.length === 1) {
              const queueItem = oldState.adQueue[0];
              return {
                ...oldState,
                bigIndex: queueItem.restoreBigIndex,
                lastBigIndex: queueItem.restoreLastBigIndex,
                isOpen: queueItem.restoreBigIndex < 0,
                adQueue: [],
                adState: "queue-complete",
              };
            } else {
              return {
                ...oldState,
                // restore index from before this ad so that the next ad can set it as last bigIndex (-> possible to go back to initial index after the queue is complete)
                bigIndex: oldState.lastBigIndex,
                adQueue: oldState.adQueue.slice(1),
                adState: "next-ad",
              };
            }
          });
        } else {
          setPlaybackState("all-ads-finished");
          setRequestAdState("initialized");
        }
        break;
      case "all-ads-finished":
        if (showPreroll) {
          setShowPreroll(false);
          if (!isSkipPreroll) {
            // only set skip preroll if it is not already skipped
            dispatch({ type: "asset-preroll-played", assetId });
            if(isLive) {
              dispatch({ type: "live-preroll-played" });
            }
          }
        }
        break;
      case "requested-play":
        if (!adSdkLoadedState) {
          // only skip loading ad sdk when there are no ads at all. Thus ad sdk will be loaded if there are no prerolls but midrolls enabled
          if (assetFlags.includes("NO_ADS")) {
            log.debug("ads not allowed for asset");
            setPlaybackState("all-ads-finished");
            setRequestAdState("initialized");
            break;
          }
          setPlaybackState("init-ads");
        } else {
          if (assetFlags.includes("NO_PREROLL")) {
            log.debug("preroll not allowed for asset");
            setPlaybackState("all-ads-finished");
            setRequestAdState("initialized");
            break;
          }
          if (
            showPreroll &&
            setConferenceState &&
            conferenceIndex !== undefined
          ) {
            setConferenceState((oldState) => {
              if (
                oldState.adState === "before-preroll" &&
                oldState.bigIndex === conferenceIndex
              ) {
                log.debug("add preroll to queue");
                return {
                  ...oldState,
                  adQueue: oldState.adQueue.concat({
                    adTagUrl: preRollUrl,
                    conferenceIndex: conferenceIndex,
                    restoreBigIndex: oldState.bigIndex,
                    restoreLastBigIndex: oldState.lastBigIndex,
                  }),
                  adState: "next-ad",
                };
              } else if (oldState.adQueue.length > 0) {
                return oldState;
              } else {
                setPlaybackState("ads-finished");
                setRequestAdState("initialized");
                return oldState;
              }
            });
          } else if (showPreroll) {
            loadAdCallback(preRollUrl);
          } else {
            setPlaybackState("ads-finished");
            setRequestAdState("initialized");
          }
        }
        break;
    }
  }, [
    adSdkLoadedState,
    loadAdCallback,
    playbackState,
    showPreroll,
    setConferenceState,
    setRequestAdState,
    setPlaybackState,
    conferenceIndex,
    assetFlags,
    preRollUrl,
    isSkipPreroll,
    assetId,
    isLive,
    dispatch,
  ]);

  useEffect(() => {
    if (adSdkLoadedState) {
      setRequestAdState("initialized");
    } else {
      setRequestAdState("uninitialized");
    }
  }, [adSdkLoadedState, setRequestAdState]);

  useEffect(() => {
    // log.debug("request ad state effect", requestAdState, "index", conferenceIndex);
    switch (requestAdState) {
      case "request-midroll":
        if (noMoreMidrolls) {
          log.debug("midroll not allowed for asset");
          break;
        }
        if (setConferenceState && conferenceIndex !== undefined) {
          log.debug("add midroll to queue");
          // add to queue even if there is no other ad running
          setConferenceState((oldState) => {
            const isAdRunning =
              oldState.adState === "ad-running" && oldState.adQueue.length > 0;
            return {
              ...oldState,
              adQueue: oldState.adQueue.concat({
                adTagUrl: getMidRollUrl(
                  oldState.midRollIndex,
                  midrollBlockDuration.current
                ),
                conferenceIndex: conferenceIndex,
                restoreBigIndex: isAdRunning
                  ? oldState.adQueue[0].restoreBigIndex
                  : oldState.bigIndex,
                restoreLastBigIndex: isAdRunning
                  ? oldState.adQueue[0].restoreLastBigIndex
                  : oldState.lastBigIndex,
              }),
              adState: "next-ad",
              midRollIndex: oldState.midRollIndex + 1,
            };
          });
        } else {
          loadAdCallback(
            getMidRollUrl(midrollIndex.current, midrollBlockDuration.current)
          );
          midrollIndex.current = midrollIndex.current + 1;
        }
        break;
      case "request-postroll":
        if (!showPostroll) {
          log.debug("postroll not allowed for asset");
          break;
        }
        if (setConferenceState && conferenceIndex !== undefined) {
          log.debug("add postroll to queue");
          // add to queue even if there is no other ad running
          setConferenceState((oldState) => {
            const isAdRunning =
              oldState.adState === "ad-running" && oldState.adQueue.length > 0;
            return {
              ...oldState,
              adQueue: oldState.adQueue.concat({
                adTagUrl: postRollUrl,
                conferenceIndex: conferenceIndex,
                restoreBigIndex: isAdRunning
                  ? oldState.adQueue[0].restoreBigIndex
                  : oldState.bigIndex,
                restoreLastBigIndex: isAdRunning
                  ? oldState.adQueue[0].restoreLastBigIndex
                  : oldState.lastBigIndex,
              }),
              adState: "next-ad",
            };
          });
        } else {
          loadAdCallback(postRollUrl);
        }
        break;
      case "skip":
        skipAdCallback();
        break;
      case "pause":
        pauseAdCallback();
        break;
      case "resume":
        resumeAdCallback();
        break;
    }
  }, [
    assetFlags,
    requestAdState,
    midrollIndex,
    getMidRollUrl,
    loadAdCallback,
    noMoreMidrolls,
    setConferenceState,
    skipAdCallback,
    pauseAdCallback,
    resumeAdCallback,
    conferenceIndex,
    showPostroll,
    postRollUrl,
  ]);

  return <div id="ad-container" ref={adContainerRef} />;
}
