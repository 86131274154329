import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import React, { ReactNode, useCallback } from "react";
import { useEnvironment } from "../../Environment";
import {
  errorStateCanTryAgain,
  errorStateToString,
} from "../../shared/errorStates";
import { ErrorBoundary } from "./ErrorBoundary";
import { SdtvPlayerError } from "./SdtvPlayerError";

interface ErrorViewProps {
  message: string;
  tryAgainClick?: () => void;
  loadingView?: boolean;
}

export function ErrorView({
  message,
  tryAgainClick,
  loadingView,
}: ErrorViewProps) {
  return (
    <div className="sdtv-error">
      <div className="sdtv-error-content">
        <p className="sdtv-error-message">
          {message}
          {loadingView && (
            <div className="sdtv-loading-wrapper">
              <div className="sdtv-loading" />
            </div>
          )}
        </p>
        {tryAgainClick && (
          <div className="sdtv-error-button-wrapper">
            <div className="sdtv-button" onClick={tryAgainClick}>
              Erneut versuchen
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function GlobalErrorBoundaryView({
  error,
  clearError,
  onClickHandler,
  onReset,
  additionalClass
}: {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
  onClickHandler?: () => void;
  onReset?: () => void;
  additionalClass?: string;
}) {
  const { reset } = useQueryErrorResetBoundary();
  let sdtvError = error as SdtvPlayerError;
  if (!sdtvError.state) {
    sdtvError = {
      name: error.name,
      stack: error.stack,
      message: error.message,
      cause: error.cause,
      state: "default",
    };
  }
  const message = errorStateToString(sdtvError.state);
  const showTryAgain = errorStateCanTryAgain(sdtvError.state);
  const tryAgainClick = useCallback(() => {
    if(onReset) {
      onReset();
    }
    reset();
    clearError();
  }, [reset, clearError, onReset]);
  const env = useEnvironment();
  env.trackingFunc("Player Error", sdtvError.message);

  const onClick = () => {
    if(onClickHandler) {
      onClickHandler();
    }
  }

  return (
    <React.Fragment>
    {additionalClass &&
      <div className={additionalClass} onClick={onClick}>
        <ErrorView
          message={message}
          tryAgainClick={showTryAgain ? tryAgainClick : undefined}
        />
        </div>
    }
    {!additionalClass &&
        <ErrorView
          message={message}
          tryAgainClick={showTryAgain ? tryAgainClick : undefined}
        />
    }
    </React.Fragment>
  );
}

interface GlobalErrorBoundaryProps {
  additionalClass?: string;
  onClickHandler?: () => void;
  onReset?: () => void;
  children: ReactNode;
}

export function GlobalErrorBoundary({ additionalClass, onClickHandler, onReset, children }: GlobalErrorBoundaryProps) {
  return (
    <React.Fragment>
    {additionalClass &&
      <div className={additionalClass}>
        <ErrorBoundary FallbackComponent={ (props) => GlobalErrorBoundaryView({...props, onClickHandler, additionalClass, onReset}) } >
          {children}
        </ErrorBoundary>
      </div>
    }
    {!additionalClass &&
        <ErrorBoundary FallbackComponent={ (props) => GlobalErrorBoundaryView({...props, onClickHandler, onReset}) } >
          {children}
        </ErrorBoundary>
    }
    </React.Fragment>
  );
}
