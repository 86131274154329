import { createRoot, Root } from "react-dom/client";
import { Player, PlayerProps } from "../components/Player";
import { parseProps, PropsParser, requireOptionalString } from "./propsParser";
import log from "loglevel";

const MuxElementPropsParser: PropsParser<PlayerProps> = {
  id: (i) => requireOptionalString("id", i),
  src: (i) => requireOptionalString("src", i),
  imageId: (i) => requireOptionalString("imageId", i),
  host: (i) => requireOptionalString("host", i),
  imageHost: (i) => requireOptionalString("imageHost", i),
};

class MuxElement extends HTMLElement {
  private _reactRoot: Root;

  constructor() {
    super();
    this._reactRoot = createRoot(this);

    log.setLevel(log.levels.DEBUG);
  }

  connectedCallback() {
    this.render();
  }

  disconnectedCallback() {
    this._reactRoot.unmount();
  }

  attributeChangedCallback() {
    // newValue: string | null // oldValue: string | null, // name: string,
    this.render();
  }

  props(): PlayerProps {
    return parseProps(MuxElementPropsParser, (k) => this.getAttribute(k));
  }

  render() {
    const parsedProps = this.props();
    this._reactRoot.render(<Player {...parsedProps} />);
  }
}

export function init() {
  window.customElements.define("sdtv-mux", MuxElement);
}
