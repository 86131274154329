import { formattedTime } from "../../shared/timeHelper";

interface TimeProps {
  progress: number;
  duration: number;
  isLive: boolean;
}

export function Time({ progress, duration, isLive }: TimeProps) {
  return (
    <div className="sdtv-controls-time">
      {isLive ? (
        // TODO: STYLE
        // TODO: make clickable to jump back to live?
        <span style={{ color: "red" }}>LIVE</span>
      ) : (
        <>
          <time>{formattedTime(Math.round(progress))}</time>
          <span> / </span>
          <time>{formattedTime(Math.round(duration))}</time>
        </>
      )}
    </div>
  );
}
