import "../styles/index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { MuxPlayerWrapper } from "./MuxPlayerWrapper";
import { AssetPlayer } from "./AssetPlayer";
import { useCallback, useEffect, useState } from "react";
import { LoadingIndicator } from "./common/LoadingIndicator";
import { Environment, EnvironmentProps } from "../Environment";
import { ConferencePlayer } from "./ConferencePlayer";
import { GlobalErrorBoundary } from "./common/GlobalErrorBoundary";
import { Asset } from "../model/Asset";
import { AdMeta } from "../model/AdMeta";
import { TrackAction } from "../shared/trackingActions";
import { randomInt } from "../shared/numberHelper";
import log from 'loglevel';
import {PlayerStateProvider, usePlayerStateDispatch} from "../PlayerStateContext";
import CastProvider from "../shared/CastProvider";
import useCastPlayer from "../shared/useCastPlayer";
import { ChromecastInfos } from "../model/ChromecastInfos";


const DEFAULT_API_HOST = "https://api.sportdeutschland.tv";
const STAGE_API_HOST = "sdtv-stage.teraone-preview.de";
const DEFAULT_IMAGE_HOST = "https://img-cdn.sportdeutschland.tv";
const STAGE_IMAGE_HOST = "https://img-cdn.sdtv-stage.teraone-preview.de";

export interface PlayerProps {
  id?: string;
  src?: string;
  imageId?: string;
  host?: string;
  imageHost?: string;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: (attemptIndex) => attemptIndex * 500,
    },
  },
});

export function Player({ id, src, imageId, host, imageHost }: PlayerProps) {
  const [assets, setAssets] = useState<Asset[]>();
  const [imageSize, setImageSize] = useState<string>();
  const [playerKey, setPlayerKey] = useState(0);
  const [isAirplayActive, setIsAirplayActive] = useState<boolean>(false);
  const [isPiPActive, setIsPiPActive] = useState<boolean>(false);


  const default_api = (process.env.NODE_ENV === 'staging') ? STAGE_API_HOST : DEFAULT_API_HOST
  const default_image = (process.env.NODE_ENV === 'staging') ? STAGE_IMAGE_HOST : DEFAULT_IMAGE_HOST
  console.log("process.env.NODE_ENV", process.env.NODE_ENV, default_api, default_image);

  const [envProps, setEnvProps] = useState<EnvironmentProps>({
    isApp: false,
    stop: false,
    setIsAirplayActive: setIsAirplayActive,
    isPiPActive: isPiPActive,
    setIsPiPActive: setIsPiPActive,
    trackingFunc: () => undefined,
    stopVideo: () => undefined,
    resumeVideo: () => undefined,
    startChromecastFunc: () => undefined,
    host: host === undefined ? default_api : host,
    imageHost: imageHost === undefined ? default_image : imageHost,
  });

  const resumePlayer = useCallback(() => {
    if (isAirplayActive || isPiPActive) {
      return
    }

    setEnvProps((old) => {
      return {
        ...old,
        stop: false,
      };
    });
  }, [isAirplayActive, isPiPActive]);

  const stopPlayer = useCallback(() => {
    if (isAirplayActive || isPiPActive) {
      return
    }

    setEnvProps((old) => {
      return {
        ...old,
        stop: true,
      };
    });
  }, [isAirplayActive, isPiPActive]);

  const initPlayer = useCallback(
    (
      externalAssets: Asset[],
      bearerToken?: string,
      imageSize?: string,
      adMeta?: AdMeta,
      trackingFunc?: (action: string, additionalInfo?: string) => void,
      onFullscreenBtnFunc?: (value: boolean) => void,
      onConfAssetChangedFunc?: (index: number) => void,
      onControlsVisibilityFunc?: (value: boolean) => void,
      adsFinishedFunc?: () => void,
      startChromecastFunc?: (chromecastInfos: ChromecastInfos) => void
    ) => {
      if (isAirplayActive || isPiPActive) {
        return
      }

      setPlayerKey(randomInt());
      if (
        externalAssets !== null &&
        externalAssets !== undefined &&
        externalAssets.length > 0
      ) {
        const doTrack = (action: TrackAction, additionalInfo?: string) => {
          log.debug("trackingFunc:", action, "Additional:", additionalInfo, "trackingFunc:", !!trackingFunc);
          if (trackingFunc) {
            trackingFunc(action, additionalInfo);
          }
        };
        setEnvProps(old => {
          return {
            ...old,
            isApp: true,
            bearerToken: bearerToken,
            stop: false,
            setIsAirplayActive: setIsAirplayActive,
            adMeta: adMeta,
            stopVideo: stopPlayer,
            resumeVideo: resumePlayer,
            adsFinishedFunc: adsFinishedFunc,
            startChromecastFunc: startChromecastFunc,
            trackingFunc: doTrack,
            onFullscreenBtnFunc: (value: boolean) => {
              log.debug("onFullscreenBtnFunc:", value, "onFullscreenBtnFunc:", !!onFullscreenBtnFunc);
              if (onFullscreenBtnFunc) {
                onFullscreenBtnFunc(value);
              }
            },
            onConfAssetChangedFunc: (index: number) => {
              log.debug("onConfAssetChangedFunc:", index, "onConfAssetChangedFunc:", !!onConfAssetChangedFunc);
              if (onConfAssetChangedFunc) {
                onConfAssetChangedFunc(index);
              }
            },
            onControlsVisibilityFunc: (value: boolean) => {
              log.debug("onControlsVisibilityFunc:", value, "onControlsVisibilityFunc:", !!onControlsVisibilityFunc);
              if (onControlsVisibilityFunc) {
                onControlsVisibilityFunc(value);
              }
            },
          }
        });
        setImageSize(imageSize);
        setAssets(externalAssets);
        doTrack("Init");
      } else {
        log.error("No assets provided");
      }
    },
    [isAirplayActive, isPiPActive, stopPlayer, resumePlayer]
  );
  useEffect(() => {
    if (id && src) {
      setAssets([
        {
          id: id,
          image_id: imageId,
          image_url: imageId,
          videos: [{ src: src }],
          currently_live: false,
          type: "VIDEO",
          flags: [],
        },
      ]);
    }
  }, [id, imageId, src]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).initPlayer = initPlayer;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).stopPlayer = stopPlayer;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).resumePlayer = resumePlayer;

  const playerElement = (
    <PlayerElement
      playerKey={playerKey}
      assets={assets}
      imageSize={imageSize}
    />
  );

  return (
    <Environment {...envProps}>
      <QueryClientProvider client={queryClient}>
        <PlayerStateProvider>
          <CastProvider>
            <GlobalErrorBoundary>{playerElement}</GlobalErrorBoundary>
          </CastProvider>
        </PlayerStateProvider>
      </QueryClientProvider>
    </Environment>
  );
}

interface MuxWrapperElementProps {
  assets: Asset[] | undefined;
  imageSize: string | undefined;
  playerKey: number;
}

function PlayerElement({
  assets,
  imageSize,
  playerKey,
}: MuxWrapperElementProps) {
  // only for debug purposes
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  (window as any).startMidroll = [];
  const castPlayer = useCastPlayer();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    if ((window as any).flutter_inappwebview?.callHandler) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
      (window as any).flutter_inappwebview.callHandler("playerReady");
      log.debug("MuxWrapperElement: flutter_inappwebview playerReady");
    } else {
      log.debug("MuxWrapperElement: flutter_inappwebview not available");
      window.addEventListener("flutterInAppWebViewPlatformReady", () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
        (window as any).flutter_inappwebview?.callHandler("playerReady");
      });
    }
  }, []);

  const dispatch = usePlayerStateDispatch()

  if (assets && assets.length > 0) {
    if (assets.length === 1) {
      const asset = assets[0];
      if(asset.videos || asset.livestream){
        return <GlobalErrorBoundary onReset={() => {
          dispatch({type: 'asset-reset', assetId: asset.id})
        }}>
          <AssetPlayer
              key={playerKey}
              asset={asset}
              imageSize={imageSize}
              castPlayer={castPlayer}
          />
        </GlobalErrorBoundary>

      }
    } else if (assets.length > 1) {
      // ==== (LIVE) CONFERENCE ====
      return (
        <GlobalErrorBoundary onReset={() => {
          assets.forEach((asset) => {
            dispatch({type: 'asset-reset', assetId: asset.id})
          });
        }}>
          <ConferencePlayer
            key={playerKey}
            assets={assets}
            imageSize={imageSize}
            castPlayer={castPlayer}
          />
        </GlobalErrorBoundary>
      );
    }
  }
  return <LoadingIndicator />;
}
