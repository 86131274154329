import {
  RefObject,
  useEffect,
  useState,
  useCallback,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import { useConferenceEnvironment } from "../components/ConferenceEnvironment";
import { useEnvironment } from "../Environment";
import { PlaybackState } from "./videoStates";

// eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access

// eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WebKitPlaybackTargetAvailabilityEvent: any;
  }
  interface WebkitVideo {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkitShowPlaybackTargetPicker: any;
  }
  interface HTMLVideoElement {
    webkitCurrentPlaybackTargetIsWireless: boolean;
  }
}

interface AirplayProps {
  videoRef: RefObject<HTMLVideoElement>;
  index?: number;
  playbackState: PlaybackState;
  setPlaybackState: Dispatch<SetStateAction<PlaybackState>>;
}

export function useAirplayState({
  videoRef,
  index,
  playbackState,
  setPlaybackState,
}: AirplayProps): [boolean, () => void, boolean] {
  const [showAirplay, setShowAirplay] = useState(false);
  const [showActiveAirplay, setShowActiveAirplay] = useState(false);
  const conferenceEnv = useConferenceEnvironment();
  const [videoRefById, setVideoRefById] = useState<HTMLVideoElement | null>(
    null
  );
  const { setIsAirplayActive } = useEnvironment();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const airplayInterval = useRef<any>(null);

  useEffect(() => {
    if (window.WebKitPlaybackTargetAvailabilityEvent && videoRefById) {
      videoRefById?.addEventListener(
        "webkitplaybacktargetavailabilitychanged",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (event: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          switch (event.availability as string) {
            case "available":
              setShowAirplay(true);
              break;
            case "not-available":
              setShowAirplay(false);
              break;
          }
        }
      );
      videoRefById?.addEventListener(
        "webkitcurrentplaybacktargetiswirelesschanged",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        () => {
          const isWireless: boolean =
            videoRefById.webkitCurrentPlaybackTargetIsWireless ?? false;

          setShowActiveAirplay(isWireless);
          setIsAirplayActive(isWireless);

          if (!isWireless) {
            setPlaybackState("paused");
          }
        }
      );
      // eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      airplayInterval.current = setInterval(() => {
        if (window.WebKitPlaybackTargetAvailabilityEvent) {
          setShowAirplay(true);
        }
        const isWireless: boolean =
          videoRefById.webkitCurrentPlaybackTargetIsWireless ?? false;

        setShowActiveAirplay(isWireless);
        setIsAirplayActive(isWireless);
      }, 500);
    } else if (window.WebKitPlaybackTargetAvailabilityEvent) {
      //airplay possible, but no video
      setShowAirplay(true);
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      clearInterval(airplayInterval.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRefById]);


  const getVideoElement = useCallback(() => {
    return document.getElementById(
      `sdtv-player-mux${
        conferenceEnv?.conferenceState?.isOpen &&
        conferenceEnv?.conferenceState?.lastBigIndex === index
          ? "-active"
          : ""
      }`
    );
  }, [conferenceEnv?.conferenceState?.isOpen, conferenceEnv?.conferenceState?.lastBigIndex, index])


  const retryCounterRef = useRef(0)
  useEffect(() => {
    if (window.WebKitPlaybackTargetAvailabilityEvent) {
      const videoInterval = setTimeout(() => {
        if (videoRefById == null) {
          retryCounterRef.current += 1

          const videoRefByIdTemp = getVideoElement()

          if(videoRefByIdTemp != null || retryCounterRef.current > 10) {
            console.log("set ", retryCounterRef.current);
            setVideoRefById(videoRefByIdTemp as HTMLVideoElement);
            clearInterval(videoInterval);
            retryCounterRef.current = 0

            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            if((videoRefByIdTemp as any).webkitCurrentPlaybackTargetIsWireless) {
              setShowActiveAirplay(true)
              setIsAirplayActive(true)
            }
          }
        }
        else {
          clearInterval(videoInterval);
        }
      }, 500);
    }
  }, [getVideoElement, videoRefById, retryCounterRef, setShowActiveAirplay, setIsAirplayActive]);

  useEffect(() => {
    if(playbackState == "playing") {
      const videoRefByIdTemp = getVideoElement()
      if(videoRefByIdTemp != null) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        if((videoRefByIdTemp as HTMLVideoElement).webkitCurrentPlaybackTargetIsWireless) {
          setShowActiveAirplay(true)
          setIsAirplayActive(true)
        }
      }
    }

  }, [playbackState, setShowActiveAirplay, setIsAirplayActive, getVideoElement])

  const clickAirplayCallback = useCallback(() => {
    if (videoRef?.current && showAirplay) {
      (videoRef?.current as any).webkitShowPlaybackTargetPicker(); // eslint-disable-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
    }
  }, [videoRef, showAirplay]);

  return [showAirplay, clickAirplayCallback, showActiveAirplay];
}
