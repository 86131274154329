import useUrlHelper from "../shared/urlHelper";
import {
  ConferenceEnvironment,
  useConferenceEnvironment,
} from "./ConferenceEnvironment";
import { AssetPlayer } from "./AssetPlayer";
import { Asset } from "../model/Asset";
import { GlobalErrorBoundary } from "./common/GlobalErrorBoundary";
import {usePlayerStateDispatch} from "../PlayerStateContext";
import {CastPlayer} from "../shared/useCastPlayer";

interface ConferencePlayerProps {
  assets: Asset[] | undefined;
  imageSize: string | undefined;
  castPlayer: CastPlayer;
}

export function ConferencePlayer(props: ConferencePlayerProps) {
  return (
    <ConferenceEnvironment>
      <ConferencePlayerComponent {...props} />
    </ConferenceEnvironment>
  );
}

function ConferencePlayerComponent({
  assets,
  imageSize,
  castPlayer
}: ConferencePlayerProps) {
  const conferenceEnv = useConferenceEnvironment();
  const { posterImage } = useUrlHelper();
  const dispatch = usePlayerStateDispatch();

  if (conferenceEnv && assets && assets.length > 1) {
    const backgroundAsset = assets[conferenceEnv.conferenceState.lastBigIndex];
    const backgroundImageUrl =
      conferenceEnv.conferenceState.lastBigIndex > -1 &&
      backgroundAsset.image_id
        ? posterImage(backgroundAsset.image_id, imageSize)
        : undefined;
    return (
      <div
        className="sdtv-conference"
        data-open={conferenceEnv.conferenceState.isOpen}
      >
        {conferenceEnv.conferenceState.isOpen && (
          <div
            className="sdtv-conference-backdrop"
            onClick={conferenceEnv.toggleConference}
          >
            <img
              className="sdtv-conference-background"
              src={backgroundImageUrl}
            />
          </div>
        )}
        <div className="sdtv-conference-assets">
          {conferenceEnv.conferenceState.showControls ? (
            <div
              className="sdtv-conference-button"
              onClick={conferenceEnv.toggleConference}
            >
              Konferenz
            </div>
          ) : null}
          <div
            className="sdtv-conference-assets-wrapper"
            data-amount={assets.length}
          >
            {assets.map((asset, index) => {
              const isBig = index === conferenceEnv.conferenceState.bigIndex
              const className =
                conferenceEnv.conferenceState.showControls ||
                index === conferenceEnv.conferenceState.bigIndex
                  ? undefined
                  : "hidden";

              const clickHandler = () => {
                conferenceEnv.setConferenceState((oldState) => {
                  return {
                    ...oldState,
                    lastBigIndex: oldState.bigIndex,
                    bigIndex: index,
                    isOpen: false,
                  };
                });
              }
              if(asset.videos || asset.livestream){
                return <GlobalErrorBoundary key={asset.id} additionalClass={isBig ? "sdtv-player-container-big" : "sdtv-player-container-small"} onClickHandler={clickHandler} onReset={() => {
                  dispatch({ type: "asset-reset", assetId: asset.id })
                }}>
                  <AssetPlayer
                    className={className}
                    key={index}
                    asset={asset}
                    // web using default size (1920x1080) for now
                    imageSize={imageSize}
                    conferenceIndex={index}
                    castPlayer={castPlayer}
                  />
                </GlobalErrorBoundary>
              }
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
