export type ErrorState =
  | "player-error"
  | "api-error"
  | "media-error"
  | "invalid-params"
  | "missing-video-source"
  // api errors (are they all?)
  | "asset-not-available"
  | "livestream-not-available"
  | "age-not-verified"
  | "age-non-compliant"
  | "youth-protection-pin-not-set"
  | "youth-protection-pin-expired"
  | "youth-protection-session-missing"
  | "without-successful-purchase"
  | "content-currently-not-purchased"
  | "content-not-allowed-for-region"
  | "too-many-concurrent-access-sessions"
  | "token-invalid"
  | "ad-blocker-enabled"
  | "default";

export interface ApiError {
  error: string;
}

export const apiErrorToErrorState = (apiError: ApiError): ErrorState => {
  return apiErrorMessageToErrorState(apiError.error);
};

export const apiErrorMessageToErrorState = (message: string): ErrorState => {
  switch (message) {
    case "INVALID_PARAMS":
      return "invalid-params";
    case "ASSET_NOT_AVAILABLE":
      return "asset-not-available";
    case "LIVESTREAM_NOT_AVAILABLE":
      return "livestream-not-available";
    case "AGE_NOT_VERIFIED":
      return "age-not-verified";
    case "AGE_NON_COMPLIANT":
      return "age-non-compliant";
    case "YOUTH_PROTECTION_PIN_NOT_SET":
      return "youth-protection-pin-not-set";
    case "YOUTH_PROTECTION_PIN_EXPIRED":
      return "youth-protection-pin-expired";
    case "YOUTH_PROTECTION_SESSION_MISSING":
      return "youth-protection-session-missing";
    case "WITHOUT_SUCCESSFUL_PURCHASE":
      return "without-successful-purchase";
    case "CONTENT_CURRENTLY_NOT_PURCHASED":
      return "content-currently-not-purchased";
    case "CONTENT_NOT_ALLOWED_FOR_REGION":
      return "content-not-allowed-for-region";
    case "TOO_MANY_CONCURRENT_ACCESS_SESSIONS":
      return "too-many-concurrent-access-sessions";
    case "TOKEN_INVALID":
      return "token-invalid";
    case "AD_BLOCKER_ENABLED":
      return "ad-blocker-enabled";
    default:
      return "api-error";
  }
};

export const errorStateToString = (errorState: ErrorState): string => {
  switch (errorState) {
    case "api-error":
    case "token-invalid":
    case "invalid-params":
    case "missing-video-source":
      return "Fehler beim Laden der Daten.";
    case "media-error":
    case "player-error":
      return "Fehler beim Abspielen deines Videos.";
    case "too-many-concurrent-access-sessions":
      return "Du hast aktuell zu viele Inhalte gleichzeitig geöffnet.";
    case "ad-blocker-enabled":
      return "Bitte deaktiviere deinen Adblocker und versuche es erneut!";
    case "asset-not-available":
      return "Dieses Video ist aktuell nicht verfügbar. Bitte versuche es später noch einmal.";
    case "livestream-not-available":
      return "Dieser Livestream ist aktuell nicht verfügbar. Bitte versuche es später noch einmal.";
    case "age-not-verified":
      return "Bitte verifiziere dein Alter und versuche es erneut.";
    case "age-non-compliant":
      return "Dieser Inhalt ist für dein Alter nicht verfügbar.";
    case "youth-protection-pin-not-set":
      return "Bitte setze deine Jugendschutz PIN in den Einstellungen und versuche es erneut.";
    case "youth-protection-pin-expired":
      return "Deine Jugendschutz PIN ist abgelaufen. Bitte ändere diese in den Einstellungen und versuche es erneut.";
    case "youth-protection-session-missing":
      return "Dieser Inhalt erfordert eine Verifizierung deines Alters. Logge dich ein und versuche es erneut.";
    case "without-successful-purchase":
    case "content-currently-not-purchased":
      return "Du kannst diesen Inhalt derzeit hier noch nicht erwerben.";//"Du kannst diesen Inhalt derzeit noch nicht in der App erwerben.";
    case "content-not-allowed-for-region":
      return "Dieser Inhalt ist in deinem Land leider nicht verfügbar.";
    case "default":
    default:
      return "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später noch einmal.";
  }
};

export const errorStateCanTryAgain = (errorState: ErrorState): boolean => {
  switch (errorState) {
    case "api-error":
    case "token-invalid":
    case "invalid-params":
    case "missing-video-source":
    case "media-error":
    case "player-error":
    case "ad-blocker-enabled":
    case "too-many-concurrent-access-sessions":
    case "default":
      return true;
    case "asset-not-available":
    case "livestream-not-available":
    case "age-not-verified":
    case "age-non-compliant":
    case "youth-protection-pin-not-set":
    case "youth-protection-pin-expired":
    case "youth-protection-session-missing":
    case "without-successful-purchase":
    case "content-currently-not-purchased":
    case "content-not-allowed-for-region":
    default:
      return false;
  }
};

export const sendErrorStateToBugsnag = (errorState: ErrorState): boolean => {
  switch (errorState) {
    case "api-error":
    case "token-invalid":
    case "invalid-params":
    case "missing-video-source":
    case "media-error":
    case "player-error":
    case "default":
      return true;
    case "ad-blocker-enabled":
    case "too-many-concurrent-access-sessions":
    case "asset-not-available":
    case "livestream-not-available":
    case "age-not-verified":
    case "age-non-compliant":
    case "youth-protection-pin-not-set":
    case "youth-protection-pin-expired":
    case "youth-protection-session-missing":
    case "without-successful-purchase":
    case "content-currently-not-purchased":
    case "content-not-allowed-for-region":
    default:
      return false;
  }
};
