export const formattedTime = (seconds: number) => {
  if(seconds > 0 && seconds < 3600 * 24 *10) {
    const date = new Date(seconds * 1000);

    return seconds < 3600 * 24
      ? seconds < 3600
        ? date.toISOString().slice(14, 19)
        : date.toISOString().slice(11, 19)
      : // > 24 hours needs special handling
        `${Math.floor(seconds / 3600)}:${date.toISOString().slice(14, 19)}`;
  }
  else {
    return "00:00"
  }
};
